import React from "react";
import jsPDF from "jspdf";


require('jspdf-autotable');

 const exportPDFFlavours = (props:any) => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape
    const marginLeft = 15;
    const doc = new jsPDF(orientation, unit, size);
    doc.setFontSize(20);
    // const Heading = `${new Date( ).toLocaleDateString('en-us')} Flavor Table`;
    // console.log("flavor",props);
    
    
    const rows=props?.map((item:any,index:any)=>{
        return[
            index+1,
            item.productName,
            item.flavourName,
            item.ShortCode,
            item.Barcode,
        ]
    })

    const headers = [["S.No." ,"Product Category","Flavors Name" , "Short Code","Barcode"]]
    let content = {
        startY: 60,
        head: headers,
        body: rows
      };
  
    //   doc.text(Heading, marginLeft, 30); 
      // @ts-ignore
      doc.autoTable(content);
    //   doc.save("PoshTable.pdf")
      doc.save(`${new Date( ).toLocaleDateString('en-us')} Flavor Table.pdf`)
    }
  
export default exportPDFFlavours