import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useLocation, useNavigate} from 'react-router-dom';
import AppButton from '../button/Button';
import ContentHeader from '../content-header/ContentHeader';
import PoshInputs from '../Forms/InputForms/Inputs';
import apiResponseGenerator from '@app/utils/apiResponseGenerator';

function ViewUserRole() {
  const {state} = useLocation();
  const [roleName, setroleName] = useState('');
  const [disable, setdisable] = useState(true);
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [savedPermissions, setSavedPermissions] = useState<any>([]);
  
  useEffect(() => {
    getByIdUserRoles(state);
  }, []);

  const getByIdUserRoles = async (id: any) => {
    const result = await apiResponseGenerator({
      dispatchFunction: dispatch,
      method: "get",
      url: `roles/get/${id}`
    });
    if (result) {
      const response = result.data;
      response.map((element: any) => {
        setroleName(element.roleName);
        setSavedPermissions(element.permission);
      });
    }
  };

  return (
    <div>
      <ContentHeader title="View User Role" />
      <section className=" bg-white p-4 rounded m-3 mb-3">
        <div className="table-responsive-xxl">
          <div
            className="inputWrapper p-0 col-sm-4 col-12"
            style={{marginLeft: '-7px'}}
          >
            <PoshInputs
              title="Role Name"
              type={'text'}
              value={roleName}
              disabled={true}
              readOnly={true}
            />
          </div>

          <table className="table">
            <thead className="thead-light">
              <tr>
                <th scope="col">Title</th>
                <th scope="col"></th>
                <th scope="col"></th>
                <th scope="col">View</th>
                <th scope="col">Edit</th>
                <th scope="col">Delete</th>
              </tr>
            </thead>
            <tbody>
              {savedPermissions?.map((items: any, index: number) => {
                return (
                  <tr key={index}>
                    <td scope="row">{items.title}</td>
                    <td></td>
                    <td></td>
                    <td>
                      <label className="container d-flex justify-content-center">
                        <input
                          name="view"
                          defaultChecked={items.view}
                          type="checkbox"
                          disabled={true}
                        />
                        <div className="checkmark" />
                      </label>
                    </td>

                    <td>
                      <label className="container d-flex justify-content-center">
                        <input
                          name="edit"
                          defaultChecked={items.edit}
                          type="checkbox"
                          disabled={true}
                        />
                        <div className="checkmark" />
                      </label>
                    </td>

                    <td>
                      <label className="container d-flex justify-content-center">
                        <input
                          name="delete"
                          defaultChecked={items.delete}
                          disabled={true}
                          type="checkbox"
                        />
                        <div className="checkmark" />
                      </label>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="d-flex justify-content-end">
            <AppButton
              children={'Back'}
              onClick={() => {
                navigate('/admin/user-and-role/userroles');
              }}
            />
          </div>
        </div>
      </section>
    </div>
  );
}

export default ViewUserRole;
