import React from "react";
import jsPDF from "jspdf";
import moment from "moment";



require('jspdf-autotable');

 const exportPDF = (data:any) => {
  const unit = "pt";
  const size = "A4"; // Use A1, A2, A3 or A4
  const orientation = "portrait"; // portrait or landscape
  const marginLeft = 15;
  const doc = new jsPDF(orientation, unit, size);
  doc.setFontSize(20);

    const rows = data?.map((item:any,index:any)=>{
        return[
          index+1,
          item.productSKU,
          item.productName,
          item.volume,
          moment(item?.created_at).format('MM/DD/YYYY'),
          item.status,
        ]
      })

    const headers = [["S.No.","SKU","Product Category",'Volume',"Created at", "Status "]]
    let content = {
        startY: 60,
        head: headers,
        body: rows
      };
  
      // doc.text(Heading, marginLeft, 30);
      // @ts-ignore
      doc.autoTable(content);
      doc.save(`${new Date( ).toLocaleDateString('en-us')} Product Category.pdf`)
    }


export default exportPDF