export function toFixedWithoutRounding(number: number, precision: number): string {
    // Convert number to string
    const numStr: string = number.toString();
    
    // Check if it's a whole number
    if (numStr.indexOf('.') === -1) {
        return numStr; // Return as it is
    }
    
    // Split number into integer and decimal parts
    const parts: string[] = numStr.split('.');
    const integerPart: string = parts[0];
    let decimalPart: string = parts[1];
    
    // Truncate decimal part to specified precision
    if (precision >= decimalPart.length) {
        return numStr; // Precision is greater than or equal to the number of decimal places
    } else {
        decimalPart = decimalPart.substr(0, precision); // Truncate decimal part
    }
    
    // Combine integer and decimal parts and return
    return integerPart + '.' + decimalPart;
}