
import React from 'react';
import {ContentHeader} from '@components';
import Flavor from '@app/components/Flavors/Flavors';

const Flavors = () => {
  return (
    <div>
      <ContentHeader title="Flavors" />
        <Flavor/> 
    </div>
  );
};

export default Flavors;
