import React from 'react';
import Form from 'react-bootstrap/Form';


export const ActiveDropdown=(props:any)=> {
  return (
    <div className="container-fluid">
      <div className="row" style={{marginLeft:"-13px"}}>
        <div className="mb-3 ">
          <label htmlFor="validationCustom03" className="form-label mb-1 ml-1 d-flex">
            <span>{props.title && `Status`}</span>
            <span style={{color: 'red'}}>{props.validate}</span>
          </label>
          <Form.Select onChange={props.onChange} value={props.value} defaultValue={props.defaultValue}  aria-label="Default select example ml-2"  className="form-control "  id="validationCustom03">
              <option value="1" selected>Active</option>
              <option value="0">In-Active</option>
          </Form.Select>
        </div>
      </div>
    </div>
  );
}

