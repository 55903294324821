import React, {useEffect, useState} from 'react';
import {ContentHeader} from '@app/components';
import {useNavigate, useParams} from 'react-router-dom';
import axios from 'axios';
import {useDispatch, useSelector} from 'react-redux';
import {BASE_URL} from '@app/components/constants/app';
import jsPDF from 'jspdf';
import AppButton from '@app/components/button/Button';
import {ScaleLoader} from 'react-spinners';
import {downloadExcel} from 'react-export-table-to-excel';
import apiResponseGenerator from '@app/utils/apiResponseGenerator';
import swal from 'sweetalert';
import moment from 'moment';
import 'jquery/dist/jquery.min.js';
// import $ from 'jquery';
import 'datatables.net';
import 'datatables.net-dt/js/dataTables.dataTables';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';

function ShipmentReceivingsTable() {
  const param: any = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector((state: any) => state.auth.token);
  const [dataAll, setAllData] = useState([]);
  const [loading, setLoading] = useState<boolean>();
  const [FilterBtn, setFilterBtn] = useState<any>(true);
  const [startDate, setStartDate] = useState<any>('');
  const [endDate, setEndDate] = useState<any>('');

  const config = {
    Accept: 'application/json',
    Authorization: `Bearer ${token}`
  };

  let local: any = localStorage.getItem('userInfo');
  const permissionsArray: any = JSON.parse(local).permissions;

  useEffect(() => {
    getAllShipments();
  }, []);

  const getAllShipments = async () => {
    setLoading(true);
    const result = await apiResponseGenerator({
      dispatchFunction: dispatch,
      method: 'get',
      url: 'shipment/getAll'
    });
    if (result) {
      setLoading(false);
      const response = result.data;
      setAllData(response);

      const tableData = response.map((item: any, index: number) => {
        return {
          index: index + 1,
          id: item.id,
          lotno: item.lotNo,
          airwayNo: item.airwayNo,
          order_id: item.order_id,
          dispatchedDate: moment(item.dispatchedDate).format('MM/DD/YYYY'),
          expectedDate: moment(item.expectedDate).format('MM/DD/YYYY'),
          status: item.status == '0' ? 'Dispatched' : 'Received'
        };
      });

      setTimeout(() => {
        tableData &&
          $('#example').DataTable({
            data: tableData,
            columns: [
              {data: 'index'},
              {data: 'lotno'},
              {data: 'airwayNo'},
              {data: 'dispatchedDate'},
              {data: 'expectedDate'},
              {data: 'status'},
              {
                title: 'Action',
                 render: function (data, type, row, meta) {
                  const viewIcon = `
                <img src="/assets/eye.svg"
                 data-toggle="tooltip"
                 data-html="true"
                 id="veiw"
                 data-bs-placement="top"
                 title="View" height="600"
                 >`;

                  const deleteIcon = `<img src="/assets/delete.svg"
                data-toggle="tooltip"
                data-html="true"
                data-bs-placement="top"
                id="delete"
                title="Delete" height="600"
                >`;

                  const editIcon = `<img src="/assets/edit.svg"
                data-toggle="tooltip"
                id="edit"
                data-html="true"
                data-bs-placement="top"
                title="Edit" height="600"
                >`;

                  let receivedIcon = '';

                  if (row.status && row.status.toLowerCase() === 'dispatched') {
                    receivedIcon = `<img 
                   src="/assets/check.svg"
                   data-toggle="tooltip"
                   data-html="true"
                   data-bs-placement="top"
                   title="Received"
                   id="received"
                   height="600"
                   >`;
                  }

                  let actionIcons = `<div class='iconWapper'> ${viewIcon} ${editIcon}  ${deleteIcon} ${receivedIcon} </div>`;
                  return actionIcons;
                },

                searchable: false,
                orderable: false
              }
            ]
          });

        const table: any = document.querySelector('#example');
        table && table.removeAttribute('style');

        $(document).ready(function () {
          var table = $('#example').DataTable();

          $('#example tbody').on('click', '#veiw', function () {
            var data = table.row($(this).parents('tr')).data();
            navigate(`/admin/purchase-and-orders/view-shipments`, {
              state: data.order_id
            });
          });

          $('#example tbody').on('click', '#received', function () {
            var data = table.row($(this).parents('tr')).data();

            const receivedVeiw = {
              id: data.order_id,
              order_id: data.id,
              received: true
            };
            navigate(`/admin/purchase-and-orders/view-shipments`, {
              state: receivedVeiw
            });
          });

          $('#example tbody').on('click', '#edit', async function () {
            var data = table.row($(this).parents('tr')).data();
            if (data.status === 'Received') {
              const model:any = await swal({
                title: 'Are you sure? ',
                text: 'You will be editing already received shipment.',
                icon: 'info',
                buttons: ["Cancel", "Yes"],
                dangerMode: true,
                closeOnClickOutside: false,
              });
              if(model){
                navigate(`/admin/purchase-and-orders/edit-shipments`, {
                  state: data.order_id
                });
              }
            }else{
              navigate(`/admin/purchase-and-orders/edit-shipments`, {
                state: data.order_id
              });
            }
          });

          $('#example tbody').on('click', '#delete', function () {
            var data = table.row($(this).parents('tr')).data();
            deleteShipment(data.order_id);
          });
        });
      }, 1000);
    }
  };

  useEffect(() => {
    if (startDate !== '' && endDate !== '') {
      setFilterBtn(false);
    } else {
      setFilterBtn(true);
    }
  }, [startDate, endDate]);

  const dateFilter = async () => {
    $('#example').DataTable().destroy();
    setLoading(true);
    try {
      const response = await axios({
        url: `${BASE_URL}shipment/search`,
        method: 'get',
        headers: config,
        params: {
          to: startDate,
          from: endDate
        }
      });
      if (response) {
        setLoading(false);
        let responseData = response.data.data?.data;
        if (responseData.length === 0) return;
        const tableData = responseData.map((item: any, index: number) => {
          return {
            index: index + 1,
            id: item?.id,
            lotno: item?.lotNo,
            airwayNo: item?.airwayNo,
            order_id: item?.order_id,
            dispatchedDate: moment(item?.dispatchedDate).format('MM/DD/YYYY'),
            expectedDate: moment(item?.expectedDate).format('MM/DD/YYYY'),
            status: item?.status == '0' ? 'Dispatched' : 'Received'
          };
        });

        setTimeout(() => {
          $('#example').DataTable({
            data: tableData,
            columns: [
              {data: 'index'},
              {data: 'lotno'},
              {data: 'airwayNo'},
              {data: 'dispatchedDate'},
              {data: 'expectedDate'},
              {data: 'status'},
              {
                title: 'Action',
                 render: function (data, type, row, meta) {
                  const viewIcon = `
                <img src="/assets/eye.svg"
                 data-toggle="tooltip"
                 data-html="true"
                 id="veiw"
                 data-bs-placement="top"
                 title="View" height="600"
                 >`;

                  const deleteIcon = `<img src="/assets/delete.svg"
                data-toggle="tooltip"
                data-html="true"
                data-bs-placement="top"
                id="delete"
                title="Delete" height="600"
                >`;

                  const editIcon = `<img src="/assets/edit.svg"
                data-toggle="tooltip"
                id="edit"
                data-html="true"
                data-bs-placement="top"
                title="Edit" height="600"
                >`;

                  let receivedIcon = '';

                  if (row.status && row.status.toLowerCase() === 'dispatched') {
                    receivedIcon = `<img 
                   src="/assets/check.svg"
                   data-toggle="tooltip"
                   data-html="true"
                   data-bs-placement="top"
                   title="Received"
                   id="received"
                   height="600"
                   >`;
                  }

                  let actionIcons = `<div class='iconWapper'> ${viewIcon} ${editIcon}  ${deleteIcon} ${receivedIcon} </div>`;
                  return actionIcons;
                },

                searchable: false,
                orderable: false
              }
            ]
          });

          const table: any = document.querySelector('#example');
          table && table.removeAttribute('style');

          $(document).ready(function () {
            var table = $('#example').DataTable();

            $('#example tbody').on('click', '#veiw', function () {
              var data = table.row($(this).parents('tr')).data();
              navigate(`/admin/purchase-and-orders/view-shipments`, {
                state: data.order_id
              });
            });

            $('#example tbody').on('click', '#received', function () {
              var data = table.row($(this).parents('tr')).data();
              if (data.status === 'Dispatched') {
                const receivedVeiw = {
                  id: data.order_id,
                  order_id: data.id,
                  received: true
                };
                navigate(`/admin/purchase-and-orders/view-shipments`, {
                  state: receivedVeiw
                });
              }
            });

            $('#example tbody').on('click', '#edit', async function () {
              var data = table.row($(this).parents('tr')).data();
              if (data.status === 'Received') {
                const model:any = await swal({
                  title: 'Are you sure? ',
                  text: 'You will be editing already received shipment.',
                  icon: 'info',
                  buttons: ["Cancel", "Yes"],
                  dangerMode: true,
                  closeOnClickOutside: false,
                });
                if(model){
                  navigate(`/admin/purchase-and-orders/edit-shipments`, {
                    state: data.order_id
                  });
                }
              }else{
                navigate(`/admin/purchase-and-orders/edit-shipments`, {
                  state: data.order_id
                });
              }
            });

            $('#example tbody').on('click', '#delete', function () {
              var data = table.row($(this).parents('tr')).data();
              deleteShipment(data.order_id);
            });
          });
        }, 1000);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const PdfGenerate = () => {
    const unit = 'pt';
    const size = 'A4'; // Use A1, A2, A3 or A4
    const orientation = 'portrait'; // portrait or landscape;
    const doc = new jsPDF(orientation, unit, size);
    doc.setFontSize(20);

    const rows = dataAll?.map((item: any, index: any) => {
      return [
        index + 1,
        item.lotNo,
        item.airwayNo,
        moment(item.dispatchedDate).format('MM/DD/YYYY'),
        moment(item.expectedDate).format('MM/DD/YYYY'),
        item.status == '0' ? 'Dispatched' : 'Received'
      ];
    });

    const headers = [
      [
        'S.No.',
        'Lot No.',
        'Airway Bill No.',
        'Dispatch Date',
        'Delivery Date',
        'Status'
      ]
    ];
    let content = {
      startY: 60,
      head: headers,
      body: rows
    };
    // @ts-ignore
    doc.autoTable(content);
    doc.save(
      `${new Date().toLocaleDateString('en-us')} Shipment & Receiving Table.pdf`
    );
  };

  const ExcelGenerate: any = () => {
    const Heading: any = [
      'S.No.',
      'Lot No.',
      'Airway Bill No.',
      'Dispatch Date',
      'Delivery Date',
      'Status'
    ];
    const rows: any = dataAll?.map((item: any, index: any) => {
      return [
        index + 1,
        item.lotNo,
        item.airwayNo,
        moment(item.dispatchedDate).format('MM/DD/YYYY'),
        moment(item.expectedDate).format('MM/DD/YYYY'),
        item.status == '0' ? 'Dispatched' : 'Received'
      ];
    });

    downloadExcel({
      fileName: `${new Date().toLocaleDateString(
        'en-GB'
      )} Shipment & Receiving Table`,
      sheet: `${new Date().toLocaleDateString(
        'en-GB'
      )} Shipment & Receiving Table`,
      tablePayload: {
        header: Heading,
        body: rows
      }
    });
  };

  const deleteShipment = (id: number) => {
    swal({
      title: 'Are you sure ?',
      text: 'You want to delete this purchase order ?',
      icon: 'warning',
      buttons: ['Cancel', 'Yes'],
      dangerMode: true,
      closeOnClickOutside: false
    }).then(async (response: any) => {
      if (response) {
        const result = await apiResponseGenerator({
          dispatchFunction: dispatch,
          method: 'delete',
          url: `purchaseOrder/delete/${+id}`
        });
        if (result) {
          navigate(0);
        }
      }
    });
  };

  return (
    <div>
      <>
        <ContentHeader title="Shipment & Receiving" />
        <section className=" bg-white p-4 rounded m-3">
          <div>
            <div className="row mb-2 d-flex  col-lg-justify-content-around">
              <div className="col-xl-3">
                <div className="input-group input-group-lg mb-3">
                  <input
                    value={startDate}
                    type="date"
                    onChange={(e) => {
                      setStartDate(e.target.value);
                    }}
                    className="form-control"
                    aria-label="From"
                    aria-describedby="inputGroup-sizing-sm"
                  />
                </div>
              </div>

              <div className="col-xl-3">
                <div className="input-group input-group-lg mb-3">
                  <input
                    type="date"
                    value={endDate}
                    onChange={(e) => {
                      setEndDate(e.target.value);
                    }}
                    className="form-control"
                    aria-label="From"
                    aria-describedby="inputGroup-sizing-sm"
                  />
                </div>
              </div>

              <div className="col  d-flex justify-content-end">
                <AppButton
                  children={'Apply Filter'}
                  className="btn-lg mb-3 mr-3 btn btn-secondary"
                  disabled={FilterBtn}
                  onClick={() => {
                    dateFilter();
                  }}
                />

                <div className="btn-group">
                  <div className="dropdown">
                    <button
                      className="btn btn-dark mr-3 btn-lg mb-3  dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Export
                    </button>

                    <div
                      className="dropdown-menu ExportDropdown col-2 dropdownImg"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <div
                        className="dropdown-item d-flex flex-row"
                        onClick={() => {
                          ExcelGenerate();
                        }}
                        style={{cursor: 'pointer'}}
                      >
                        <img
                          src="/assets/excel.png"
                          className="mx-2"
                          style={{
                            height: '30px',
                            width: '30px',
                            margin: 0
                          }}
                        />
                        Excel
                      </div>
                      <div className="dropdown-divider"></div>
                      <div
                        onClick={() => {
                          PdfGenerate();
                        }}
                        className="dropdown-item d-flex flex-row "
                        style={{cursor: 'pointer'}}
                      >
                        <img
                          src="/assets/pdf.png"
                          className="mx-2"
                          style={{
                            height: '30px',
                            width: '30px',
                            margin: 0
                          }}
                        />
                        PDF
                      </div>
                    </div>
                  </div>
                </div>

                <AppButton
                  children={'Add New'}
                  className="btn-lg mb-3 "
                  onClick={() => {
                    navigate('/admin/purchase-and-orders/new-shipments');
                  }}
                />
              </div>
            </div>
          </div>
          {loading ? (
            <div>
              <div className="d-flex justify-content-center">
                <ScaleLoader color="#007bff" height={40} />
              </div>
            </div>
          ) : (
            <table id="example" className="table table-hover">
              <thead className="thead bg-primary">
                <tr>
                  <th scope="col">S.No.</th>
                  <th scope="col">Lot No.</th>
                  <th scope="col">Airway Bill No.</th>
                  <th scope="col">Dispatch Date</th>
                  <th scope="col">Delivery Date</th>
                  <th scope="col">Status</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
            </table>
          )}
        </section>
      </>
    </div>
  );
}

export default ShipmentReceivingsTable;
