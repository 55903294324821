import React from 'react';
import './img.css';

function Image(props: any) {
  return (
    <>
      {props.productName ? (
        <div className="row d-flex align-items-center">
          <div className="col-md-2">
            <img
              src={props.productImage || '/assets/notFound.png'}
              className=" img_Border"
            />
          </div>

          <div className="col-xxl-10">
            <h4 className="text-primary bolder">{`${props.productName} (${
              props.volume ? props.volume : ' '
            })`}</h4>
            <h5>#{props.productBarcode}</h5>
            <div className="main">
              <div className={props.status == 1 ? 'dot' : 'dot2'}></div>
              <span className="txt">
                {props.status == 1 ? 'Active' : 'In Active'}
              </span>
            </div>
          </div>
        </div>
      ) : (
        <> </>
      )}
    </>
  );
}

export default Image;
