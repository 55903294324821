import React from 'react'


interface FileProps {
    onChange?:any
    value?:any
}
function FileInput(props:FileProps) {
  return (

    <div className="container-fluid">
        <div className="row">
            <div className="mt-4 form-group" style={{marginLeft: "-4px"}}>
                <label htmlFor="validationCustom03" className="form-label ">Upload Image</label>
                <div className="custom-file" style={{marginLeft: "1px",width: "101%"}}>
                    <input placeholder='file' onChange={props.onChange}  type="file" className="custom-file-input" id="customFile"/>
                    <label className="custom-file-label" htmlFor="customFile">{props?.value ? props?.value?.name : "Choose File"}</label>
                        <p className="text-end  fs-6">jpg , png & jpeg allowed , max size 5 mb.</p>
                </div>
            </div>
        </div>
   </div>
    )
}

export default FileInput
