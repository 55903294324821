import React, {useEffect, useLayoutEffect, useState} from 'react';
import {ContentHeader} from '@app/components';
import AppButton from '@app/components/button/Button';
import PoshInputs from '@app/components/Forms/InputForms/Inputs';
import {useNavigate} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import swal from 'sweetalert';

function AddFormation() {
  const navigation = useNavigate();
  const [parent, setParent] = useState<number>()
  const [displayBoxes, setDisplayBoxes] = useState<number>()
  const [devicePerBoxes, setDevicePerBoxes] = useState<number>()
  const [Disable, setDisabled] = useState(true);

  useEffect(() => {
    if (parent && displayBoxes && devicePerBoxes){
      setDisabled(false)
    }else{
      setDisabled(true)
    }
    
  }, [parent,displayBoxes,devicePerBoxes])

 const onSubmit = async() => {
  const model = await swal({
    title: 'Are you sure ?',
    text: 'You want to add formation ?',
    icon: 'warning',
    buttons: [true, true],
    dangerMode: true,
    closeOnClickOutside: false
  });

  if (!model) return;
  navigation(-1)
 }
  return (
    <div>
      <ContentHeader title="Add Formation" />
      <section className=" bg-white p-4 rounded m-3">
        <form onSubmit={(e) => e.preventDefault()}>
          <div className="inputWrapper">
            <PoshInputs
              title="Parent"
              validate={"*"}
              value={parent}
              onChange={(e: any) => {
                setParent(e.target.value);
              }}
              type={'number'}
              hideControl
              readOnly={true}
            />
            <PoshInputs
              title="Display Boxes"
              validate={"*"}
              onChange={(e: any) => {
                setDisplayBoxes(e.target.value);
              }}
              value={displayBoxes}
              type={'number'}
              hideControl
              readOnly={true}
            />
          </div>
          <div className="inputWrapper">
            <PoshInputs
              title="Device Per Boxes"
              validate={"*"}
              onChange={(e: any) => {
                setDevicePerBoxes(e.target.value);
              }}
              value={devicePerBoxes}
              type={'number'}
              hideControl
              readOnly={true}
            />
            <PoshInputs
              disabled={true}
              title="Formation"
              validate={"*"}
              value={parent && displayBoxes && devicePerBoxes && +parent + +displayBoxes * +devicePerBoxes}
              type={'number'}
              hideControl
              readOnly={false}
            />
          </div>
          <div
            className="d-flex justify-content-end"
            style={{marginRight: '7px'}}
          >
            <AppButton
              children={'Cancel'}
              className="btn btn-light mr-3"
              onClick={() => {
                navigation(-1);
              }}
            />
            <AppButton
              children={'Save'}
              disabled={Disable}
              onClick={() => {
                onSubmit()
              }}
            />
          </div>
        </form>
      </section>
    </div>
  );
}

export default AddFormation;
