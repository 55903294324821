import ContentHeader from '@app/components/content-header/ContentHeader';
import {ActiveDropdown} from '@app/components/dropdown/ActiveDropdown';
import PoshInputs from '@app/components/Forms/InputForms/Inputs';
import React, {useEffect, useState} from 'react';
import FileInput from '../FileInput/FileInput';
import './AddItems.css';
import {useNavigate, useParams} from 'react-router-dom';
import AppButton from '@app/components/button/Button';
import {useDispatch} from 'react-redux';
import swal from 'sweetalert';
import {ScaleLoader} from 'react-spinners';
import apiResponseGenerator from '@app/utils/apiResponseGenerator';

function UpdateItems() {
  const navigate = useNavigate();
  const [disable, setDisable] = useState(true);
  const [loading, setloading]: any = useState<boolean>();
  const [productname, setProductName] = useState();
  const [productDes, setProductDes]: any = useState();
  const [status, setStatus]: any = useState();
  const [picture, setPicture]: any = useState();
  const [productSku, setProductSku]: any = useState();
  const [productVolume, setproductVolume]: any = useState();

  const dispatch = useDispatch();
  const {id}: any = useParams();
  const [productImage, setproductImage] = React.useState(null);

  useEffect(() => {
    getProductById(id);
  }, []);

  const getProductById = async (id: number) => {
    const result = await apiResponseGenerator({
      dispatchFunction: dispatch,
      method: 'get',
      url: `products/show/${id}`
    });
    if (result) {
      setloading(false);
      let response = result.data.data;
      setProductSku(response.productSKU);
      setProductName(response.productName);
      setStatus(+response.status);
      setProductDes(response.productDescription);
      setproductVolume(response.volume);
      setproductImage(response.productImage);
    }
  };

  const States = {
    productSku,
    productname,
    productDes,
    productVolume
  };

  useEffect(() => {
    if (productSku && productname && productDes && productVolume) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [States]);

  const UpdateProduct = async () => {
    if (productSku && productVolume && productname && productDes) {
      await swal({
        title: 'Are you sure ?',
        text: 'You want to update product category ?',
        icon: 'warning',
        buttons: [true, true],
        dangerMode: true,
        closeOnClickOutside: false
      }).then(async (res) => {
        if (res) {
          setloading(true);
          let formData = new FormData();
          formData.append('productImage', picture);
          const api_response = await apiResponseGenerator({
            dispatchFunction: dispatch,
            method: 'post',
            url: `products/upload`,
            body: formData
          });

          let imgResponse =  await !!api_response ? api_response : productImage ;

          const data = {
            productSKU: productSku,
            productName: productname,
            productDescription: productDes,
            status: status,
            volume: productVolume,
            productImage: imgResponse
          };

          const add_api_response = await apiResponseGenerator({
            dispatchFunction: dispatch,
            method: 'post',
            url: `products/update/${id}`,
            body: data
          });

          if (add_api_response) {
            setloading(false);
            swal({
              icon: 'success',
              title:"Success",
              text:`Product category updated successfully.`,
            });
            setTimeout(() => {
              navigate('/admin/products/categories');
            }, 500);
          }
        }
      });
    }
  };

  if (loading) {
    return (
      <div>
        <div className="d-flex justify-content-center">
          <ScaleLoader color="#007bff" height={40} />
        </div>
      </div>
    );
  }

  return (
    <div>
      <ContentHeader title="Update Product Categories" />
      <section className=" bg-white p-4 rounded m-3">
        <form onSubmit={(e) => e.preventDefault()}>
          <div className="inputWrapper">
            <PoshInputs
              validate={'*'}
              value={productSku}
              onChange={(e: any) => {
                setProductSku(e.target.value);
              }}
              title="SKU"
              type={'text'}
              readOnly={true}
            />
            <PoshInputs
              validate={'*'}
              value={productname}
              onChange={(e: any) => {
                setProductName(e.target.value);
              }}
              title="Product Categories"
              type={'text'}
              readOnly={true}
            />
          </div>

          <div className="inputWrapper">
            <PoshInputs
              validate={'*'}
              value={productDes}
              onChange={(e: any) => {
                setProductDes(e.target.value);
              }}
              title="Product Description"
              type={'text'}
              readOnly={true}
            />
            <PoshInputs
              validate={'*'}
              value={productVolume}
              onChange={(e: any) => {
                setproductVolume(e.target.value);
              }}
              title="Volume"
              type={'number'}
              min={0.0}
              step={'number'}
              hideControl
            />
          </div>

          <div className="inputWrapper" style={{marginTop: '-25px'}}>
            <ActiveDropdown
              validate={'*'}
              title="Status"
              value={status}
              onChange={(e: any) => {
                setStatus(e.target.value);
              }}
            />
            <FileInput
              value={picture}
              onChange={(e: any) => {
                setPicture(e.target.files[0]);
              }}
            />
          </div>

          <div
            className="d-flex justify-content-end"
            style={{marginRight: '13px'}}
          >
            <AppButton
              children={'Cancel'}
              onClick={() => {
                navigate(-1);
              }}
              className="btn btn-light mr-3"
            />
            <AppButton
              disabled={disable}
              onClick={() => {
                UpdateProduct();
              }}
              type="submit"
              children={'Update'}
            />
          </div>
        </form>
      </section>
    </div>
  );
}

export default UpdateItems;
