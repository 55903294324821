import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import './model.css';

export default function SalesTableModal({ onHide, options, item, callBack, userIndex, onSave }: any) {
  console.log({ item })
  const [selectedOption, setSelectedOption] = useState(null);

  const onChangeConpamyName = (
    selected_user: any,
    rowIndex: number | string
  ) => {
    setSelectedOption(selected_user)
    callBack(selected_user, rowIndex)
  }

  const alt_customerData = {
    label: item?.label,
    id: +item?.id,
    value: +item?.value
  };

  return (
    <>
      <Modal
        show={true}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={onHide}
      >
        <Modal.Header closeButton className="bg-primary">
          <Modal.Title id="contained-modal-title-vcenter">
            Edit Company Name
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="m-3" style={{ height: "20rem" }}>
          <Select
            value={selectedOption || alt_customerData}
            onChange={(selected: any) => {
              onChangeConpamyName(selected, userIndex)
            }
            }
            options={options}
            placeholder="Select Company Name"
          />
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-light" onClick={onHide}>
            Close
          </Button>
          <Button onClick={onSave || onHide}>Save</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
