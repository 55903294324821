import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation, useNavigate} from 'react-router-dom';
import AppButton from '../button/Button';
import ContentHeader from '../content-header/ContentHeader';
import PoshInputs from '../Forms/InputForms/Inputs';
import './Addagensts.css';
import swal from 'sweetalert';
import {ScaleLoader} from 'react-spinners';
import {ActiveDropdown} from '../dropdown/ActiveDropdown';
import apiResponseGenerator from '@app/utils/apiResponseGenerator';

function UpdateAgents() {
  const navigate = useNavigate();
  const {state}: any = useLocation();
  const [input, setinput]:any = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    address: '',
    status: ''
  });

  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState<boolean>();
  const dispatch = useDispatch();
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  useEffect(() => {
    if (
      input.firstName &&
      input.lastName &&
      emailRegex.test(input.email) &&
      input.phone &&
      input.address
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [input]);

  useEffect(() => {
    setinput({
      ...input,
      firstName: state.firstName,
      lastName: state.lastName,
      address: state.address,
      phone: state.phone,
      status: state.status  === "Active" ? 1 : 0,
      email: state.email
    });
  }, [state]);

  const updateAgent = async () => {
    swal({
      title: 'Are you sure ?',
      text: 'You want to update agent ?',
      icon: 'warning',
      buttons: [true, true],
      dangerMode: true,
      closeOnClickOutside: false,
    }).then(async (AddProduct) => {
      setLoading(true);
      if (AddProduct) {
        let data = {
          firstName: input.firstName,
          lastName: input.lastName,
          email: input.email,
          phone: input.phone,
          address: input.address,
          status: input.status
        } 
        const result = await apiResponseGenerator({dispatchFunction: dispatch, method: "post",body: data, url: `agents/update/${state.id}`});
        if (result) {
          setLoading(false);
          navigate("/admin/shipping-agents")
        }
      }
    });
  };

  if (loading) {
    return (
      <div>
        <div className="d-flex justify-content-center">
          <ScaleLoader color="#007bff" height={40} />
        </div>
      </div>
    );
  }

  return (
    <div>
      <ContentHeader title="Update Agents" />
      <section className=" bg-white p-4 rounded m-3">
        <div>
          <div className="inputWrapper">
            <PoshInputs
              value={input.firstName}
              onChange={(e: any) => {
                setinput({...input, firstName: e.target.value});
              }}
              title="First Name"
              type={'text'}
              readOnly={true}
              validate={"*"}
            />
            <PoshInputs
              value={input.lastName}
              onChange={(e: any) => {
                setinput({...input, lastName: e.target.value});
              }}
              title="Last Name"
              type={'text'}
              readOnly={true}
              validate={"*"}
            />
          </div>

          <div className="inputWrapper">
            <PoshInputs
              value={input.email}
              onChange={(e: any) => {
                setinput({...input, email: e.target.value});
              }}
              title="Email"
              type={'email'}
              readOnly={true}
              validate={"*"}
            />
            <PoshInputs
              value={input.phone}
              onChange={(e: any) => {
                setinput({...input, phone: e.target.value});
              }}
              title="Phone"
              type={'number'}
              readOnly={true}
              hideControl
              validate={"*"}
            />
          </div>

          <div className="inputWrapper">
            <PoshInputs
              value={input.address}
              onChange={(e: any) => {
                setinput({...input, address: e.target.value});
              }}
              title="Address"
              type={'text'}
              readOnly={true}
              validate={"*"}
            />
            <ActiveDropdown
              value={input.status}
              title="Status"
              onChange={(e: any) => {
                setinput({...input, status: e.target.value});
              }}
              validate={"*"}
            />
          </div>

          <div
            className="d-flex justify-content-end"
            style={{marginRight: '10px'}}
          >
            <AppButton
              children={'Cancel'}
              onClick={() => {
                navigate('/admin/shipping-agents');
              }}
              className="btn btn-light mr-3"
            />
            <AppButton
              disabled={disabled}
              onClick={() => {
                updateAgent();
              }}
              children={'Update'}
              className="btn btn-primary"
            />
          </div>
        </div>
      </section>
    </div>
  );
}

export default UpdateAgents;
