import React, {useEffect, useState} from 'react';
import Select from 'react-select';
import { useNavigate} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {ScaleLoader} from 'react-spinners';
import ContentHeader from '@app/components/content-header/ContentHeader';
import AppButton from '@app/components/button/Button';
import '../checkbox/checkbox.css';
import IncentiveModal from '@app/components/Modal/Model';
import swal from 'sweetalert';
import moment from 'moment';
import apiResponseGenerator from '@app/utils/apiResponseGenerator';

const IncentiveAllocationTable = () => {
  const navigate = useNavigate();
  const [Invoice, setInvoices] = useState([]);
  const [selectedOption, setSelectedOption]: any = useState(null);
  const [invoiceData, setInvoiceData] = useState<any>([]);
  const [modalShow, setModalShow] = useState<any>(false);

  const dispatch = useDispatch();
  const [CustId, setCustId] = useState<any>(null);
  const [Previous, setPrevious]: any = useState();
  const [next, setNext]: any = useState();
  const [Pages, setPages]: any = useState([]);
  const [Total, setTotal] = useState<any>(0);
  const [From, setFrom] = useState<any>(0);
  const [To, setTo] = useState<any>(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState<boolean>();
  const [ModelRes, setModelRes]: any = useState([]);
  const [Active, setActive]: any = useState();
  const getLocalStorageData: any = JSON.parse(
    localStorage.getItem('Allocation_info') || '{}'
  );

  const getInvoices = async () => {
    const result = await apiResponseGenerator({dispatchFunction: dispatch, method: "get", url: `customers/getAll`});
    if (result) {
      setLoading(false);
      const temp = result.data;
      temp.map((element: any) => {
        return (
          (element['label'] = element['companyName']),
          delete element['companyName'],
          (element['value'] = element['id'])
        );
      });
      setInvoices(temp);
    }
  };

  const fetchProductFlavours = async (id:number) => {
    const result = await apiResponseGenerator({dispatchFunction: dispatch, method: "get", url: `invoice/getCustomerInvoices/${+id}`});
    if (result) {
      setLoading(false);
      setTotal(result.data?.total);
      setFrom(result.data?.from);
      setTo(result.data?.to);
      setInvoiceData(result.data.data);
      setCurrentPage(result.data.current_page);
      let arr = result.data?.links;
      arr.forEach((activelink:any) => {
        if(activelink.active == true) {
          return setActive(activelink.label)
         }
      })
      setPrevious(arr[0]);
      setNext(arr[arr.length - 1]);
      let temp = arr.slice(1, arr.length - 1);
      setPages(temp);
    }
  };

  const nextPage = async (url: string, e: any) => {
    let pagesUrl = url.slice(49);
    const result = await apiResponseGenerator({dispatchFunction: dispatch, method: "get", url: `${pagesUrl}`});
    if (result) {
      setLoading(false);
      setTotal(result.data?.total);
      setFrom(result.data?.from);
      setTo(result.data?.to);
      setInvoiceData(result.data.data);
      setCurrentPage(result.data.current_page);
      let arr = result.data?.links;
      arr.forEach((activelink:any) => {
        if(activelink.active == true) {
          return setActive(activelink.label)
         }
      })
      setPrevious(arr[0]);
      setNext(arr[arr.length - 1]);
      let temp = arr.slice(1, arr.length - 1);
      setPages(temp);
    }
  };

  const GiveIncentive = async () => {
    let result: any[] = ModelRes.map((item: any) => {
      return {id: item?.id};
    });

    let data = {
      customer_id: +CustId,
      invoices: result
    };

    const add_api_response = await apiResponseGenerator({
      dispatchFunction: dispatch,
      method: 'post',
      url: 'invoice/addIncentive',
      body: data
    });
    if (add_api_response) {
      setLoading(false);
      setModalShow(false);
      setInvoiceData([])
      const result: any = swal({
        title: 'Success',
        text: 'incentive added successfully.',
        icon: 'success',
        closeOnClickOutside: false,
      });
      if (result) {
        fetchProductFlavours(selectedOption?.id || getLocalStorageData?.id);
        navigate('/admin/incentives/incentive-allocation');
      }
    }
  };

  useEffect(() => {
    getInvoices();
    if (getLocalStorageData) {
      fetchProductFlavours(getLocalStorageData?.id);
    }
    return () =>{
      localStorage.removeItem('Allocation_info');
    }
  }, []);

  useEffect(() => {
    if (selectedOption?.id) {
      setInvoiceData([])
      fetchProductFlavours(selectedOption?.id);
    }
  }, [selectedOption]);

  const CheckboxValue = (
    id: number,
    CustomerID: number,
    index: number,
    CustomerFName: string,
    CustomerLName: string,
    TotalAmount: number,
    valueChange: boolean
  ) => {
    if (valueChange === false) {
      const newArr = ModelRes.filter((d: any) => +d.id !== +id);
      setModelRes(newArr);
    }

    if (id === invoiceData[index].id && valueChange === true) {
      setCustId(CustomerID);

      const modelObj = {
        CustomerFName,
        CustomerLName,
        id,
        TotalAmount
      };
      setModelRes([modelObj, ...ModelRes]);
    }
  };

  const SaveLocalStorage = (id:number) => {
    localStorage.removeItem('Allocation_info');
    const Allocation_obj: any = {
      id: selectedOption?.id,
      label: selectedOption?.label,
      value: selectedOption?.value
    };
    if(getLocalStorageData?.id){
      navigate(`/admin/productsinfo/flavor/${id}`,{state:getLocalStorageData})
    }else{
      navigate(`/admin/productsinfo/flavor/${id}`,{state:Allocation_obj})
    }
  };

  if (loading) {
    return (
      <div>
        <div
          className="d-flex justify-content-center"
          style={{marginTop: '20pc', alignItems: 'center'}}
        >
          <ScaleLoader color="#007bff" height={40} />
        </div>
      </div>
    );
  }

  return (
    <>
      <ContentHeader title="Incentive Allocation" />
      <section className=" bg-white p-4 rounded m-3">
        <div className=" d-flex justify-content-md-between mb-3">
          <div className="col-4" style={{marginLeft: '-7px'}}>
            <Select
              value={selectedOption || getLocalStorageData}
              onChange={(e: any) => {
                setSelectedOption(e);
              }}
              options={Invoice}
              placeholder="Select Company Name"
            />
          </div>
          <div className="md-col-2">
            <>
              <AppButton
                children={'Give Incentive'}
                disabled={ModelRes.length <= 0 ? true : false}
                onClick={() => setModalShow(true)}
              />
            </>
            <IncentiveModal
              show={modalShow}
              modelarray={ModelRes}
              onHide={() => setModalShow(false)}
              giveIncentive={() => GiveIncentive()}
              title={'Incentive'}
            />
          </div>
        </div>

        <div className="col-xxl-12 table-responsive">
          <table className="table table-hover">
            <thead className="thead- bg-primary">
              <tr>
                <th scope="col">S.No.</th>
                <th scope="col">Company Name</th>
                <th scope="col">Customer Name</th>
                <th scope="col">Invoice #</th>
                <th scope="col">Allocation Date</th>
                <th scope="col">Total Cases</th>
                <th scope="col">Incentive</th>
                <th scope="col">Total Amount</th>
                <th scope="col">Current Amount</th>
                <th scope="col">Status</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {invoiceData?.map((item: any, index: number) => {
                if (currentPage > 1) {
                  index = (currentPage - 1) * 10 + index + 1;
                } else {
                  index = index;
                }

                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.companyName}</td>
                    <td>
                      {item.custFirstName} {item.custLastName}
                    </td>
                    <td>{item.id}</td>
                    <td>{moment(item.created_at).format('MM/DD/YYYY')}</td>
                    <td>{item.total_cases}</td>
                    <td>{item.incentive_amount}</td>
                    <td>{item.grand_total}</td>
                    <td>{+item.grand_total - +item.incentive_amount}</td>
                    <td>
                      {item.status}
                    </td>
                    <td className="iconWapper">
                        <div className="d-flex justify-content-between">
                          {item.status?.toLowerCase() === "completed" ? (
                            <label className="container d-flex justify-content-center">
                              <input
                                defaultChecked={true}
                                type="checkbox"
                                disabled={true}
                              />
                              <div className="checkmark" />
                            </label>
                          ) : (
                            <label className="container d-flex justify-content-center">
                              <input
                                onChange={(e: any) =>
                                  CheckboxValue(
                                    item.id,
                                    item.CustomerID,
                                    index,
                                    item.custFirstName,
                                    item.custLastName,
                                    item.grand_total,
                                    e.target.checked
                                  )
                                }
                                type="checkbox"
                              />
                              <div className="checkmark" />
                            </label>
                          )}
                            <img
                              src="/assets/eye.svg"
                              data-toggle="tooltip"
                              data-html="true"
                              onClick={() => {
                                SaveLocalStorage(item.id);
                              }}
                              data-bs-placement="top"
                              title="View"
                            />
                        </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        {Total > 10 ? (
          <nav
            aria-label="Page navigation example"
            className="d-flex justify-content-between"
            style={{marginTop: 10}}
          >
            <div>
              <p>
                Showing {From} to {To} of <b>{Total}</b> Allocations
              </p>
            </div>
            <ul className="pagination  justify-content-end">
              <li className="page-item">
                <button
                  disabled={Previous?.url == null ? true : false}
                  className="page-link page-item"
                  onClick={(e: any) => {
                    nextPage(Previous.url, e);
                  }}
                >
                  Previous
                </button>
              </li>

              {Pages.map((item: any, index: number) => {
                let temp = (index += 1);
                let url = item.url;
                return (
                  <li
                    onClick={(e) => nextPage(url, e)}
                    key={index}
                    className={`page-item ${+Active === temp ? 'active' : ''}`}
                  >
                    <a className="page-link" href="#">
                      {temp}
                    </a>
                  </li>
                );
              })}

              <li className="page-item">
                <button
                  disabled={next?.url != null ? false : true}
                  onClick={(e) => {
                    nextPage(next.url, e);
                  }}
                  className="page-link"
                >
                  Next
                </button>
              </li>
            </ul>
          </nav>
        ) : (
          ' '
        )}
      </section>
    </>
  );
};

export default IncentiveAllocationTable;
