import React from 'react'
import ContentHeader from '../components/content-header/ContentHeader'
import FormationTable from '../components/Tables/FormationTable'

function Formation() {
  return (
  <div>
    <ContentHeader title="Formation" />
    <FormationTable/>
  </div>
  )
}

export default Formation
