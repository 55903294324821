export default class LocalStorageFunctions {
    get(Name:string,isObjectOrArray?:boolean) {
        isObjectOrArray ? JSON.parse(localStorage.getItem(Name) || "{}") : localStorage.getItem(Name)        
    }
    set(Name:string, localStorage_data:any) {
        localStorage.setItem(Name, JSON.stringify(localStorage_data))
    }
    delete(Name:string) {
        localStorage.removeItem(Name)
    } 
}