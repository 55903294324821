import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import FlavorsTable from './FlavorsTable'

function Flavors() {
  
  return (
    <div>
      <section>
       <FlavorsTable/>
      </section>
    </div>
  )
}

export default Flavors