export const sleep = (time: number) =>
  new Promise((res) => setTimeout(res, time));

export const calculateWindowSize = (windowWidth: number) => {
  if (windowWidth >= 1200) {
    return 'lg';
  }
  if (windowWidth >= 992) {
    return 'md';
  }
  if (windowWidth >= 768) {
    return 'sm';
  }
  return 'xs';
};

export const setWindowClass = (classList: string) => {
  const window: HTMLElement | null =
    document && document.getElementById('root');
  if (window) {
    // @ts-ignore
    window.classList = classList;
  }
};
export const addWindowClass = (classList: string) => {
  const window: HTMLElement | null =
    document && document.getElementById('root');
  if (window) {
    // @ts-ignore
    window.classList.add(classList);
  }
};

export const removeWindowClass = (classList: string) => {
  const window: HTMLElement | null =
    document && document.getElementById('root');
  if (window) {
    // @ts-ignore
    window.classList.remove(classList);
  }
};



export function getShortName(flavourName: string) {
  // Split the flavourName into words
  const words = flavourName.split(' ');

  // Initialize an empty string to store the short name
  let shortName = '';

  // Iterate over each word
  words.forEach(word => {
      // Take the first letter of each word and add it to the short name
      shortName += word.charAt(0);
  });

  // Return the short name in uppercase
  return shortName.toUpperCase();
}

export function numberExtract(str: string) {
  let matches = str.match(/(\d+)/);

  if (matches) {
     return matches[0]
  }

}