import { ContentHeader } from '@app/components';
import AppButton from '@app/components/button/Button';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ScaleLoader } from 'react-spinners';
import './print.css';
import swal from 'sweetalert';
import apiResponseGenerator from '@app/utils/apiResponseGenerator';
import moment from 'moment';
import { toFixedWithoutRounding } from '@app/utils/calculationFun';
import SalesTableModal from '../Modal/SaleTableModal';

const CommonTableSingleFlavor: any = () => {
  const [PrintBtn, setPrintBtn] = useState(true);
  const [Loading, setLoading] = useState(true);
  const [comapanyName, setcomapanyName] = useState('');
  const [SubTotalAmount, setSubTotalAmount]: any = useState(0);
  const [Tax, setTax]: any = useState(0);
  const [additionalTax, setAdditionalTax]: any = useState(0);
  const [isTaxEdit, setIsTaxEdit]: any = useState(false);
  const [SubTotalQty, setSubTotalQty]: any = useState(0);
  const [SubTotalVolume, setSubTotalVolume]: any = useState(0);
  const [customerName, setcustomerName] = useState('');
  const [companyEmail, setcompanyEmail] = useState('');
  const [custcompanyEmail, setcustcompanyEmail] = useState('');
  const [phone, setphone] = useState('');
  const [city, setcity] = useState('');
  const [InvoiceDate, setInvoiceDate]: any = useState('');
  const [isDateEdit, setIsDateEdit]: any = useState(false);
  const [custphone, setcustphone] = useState('');
  const [zipcode, setzipcode] = useState('');
  const [BillAddress, setBilladdress] = useState('');
  const [ShipAddress, setShipAddress] = useState('');
  const { state }: any = useLocation();
  const { id }: any = useParams();
  const [invoiceData, setInvoiceData] = useState<any[] | null>(null);
  const [invoicesIndexs, setInvoicesIndexs] = useState<number[]>([]);
  const [invoicesObjIndexs, setInvoicesObjIndexs] = useState<number[]>([]);
  const [isEmailSend, setEmailSend] = useState(false)
  const [discount, setDiscount] = useState(0)
  const [notes, setNotes] = useState("")
  const [isCompanyNameModal, SetIsCompanyNameModal] = useState<boolean>(false)
  const [customerInfo, setCustomerInfo] = useState<any>({
    value: 0,
    label: ''
  })

  const [customers, setCustomers] = useState([]);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const fetchingCustomers = async () => {
    const result = await apiResponseGenerator({
      dispatchFunction: dispatch,
      method: 'get',
      url: 'customers/getAll'
    });

    if (result) {
      let getData = result.data.map((c: any) => ({
        value: c.id,
        label: c.companyName
      }));
      setCustomers(getData);
    }
  };

  useEffect(() => {
    ViewInvoice(id);
    if (state?.id && state.label && state.value) {
      localStorage.setItem('Allocation_info', JSON.stringify(state));
    }
  }, []);

  const ViewInvoice = async (id: number) => {
    const result = await apiResponseGenerator({
      dispatchFunction: dispatch,
      method: 'get',
      url: `saleitems/getSalesByInvoice/${id}`
    });
    fetchingCustomers()
    if (result) {
      setLoading(false);

      const responseData = result.data;

      let TotalAmountArray: any = responseData.map((item: any) => {
        let TotalAmount = item.invoices.reduce((prev: any, current: any) => {
          return prev + +current.total_price;
        }, 0);

        let TotalQty = item.invoices.reduce((prev: any, current: any) => {
          return prev + +current.qty;
        }, 0);

        let TotalVolume: any = item.invoices.reduce(
          (prev: any, current: any) => {
            return prev + +current.volume * current.qty;
          },
          0
        );

        return {
          ...item,
          TotalAmount: TotalAmount,
          TotalQty: TotalQty,
          TotalVolume: TotalVolume
        };
      });

      setInvoiceData(TotalAmountArray);

      let TotalVolume: any = TotalAmountArray.reduce(
        (prev: any, current: any) => {
          return prev + +current.TotalVolume;
        },
        0
      );
      setSubTotalVolume(TotalVolume);

      let SubTotal: any = TotalAmountArray.reduce((prev: any, current: any) => {
        return prev + +current.TotalAmount;
      }, 0);

      setSubTotalAmount(SubTotal);



      let SubTotalQty: any = TotalAmountArray.reduce(
        (prev: any, current: any) => {
          return prev + +current.TotalQty;
        },
        0
      );

      setSubTotalQty(SubTotalQty);

      let response = result.data.map((item: any) => {
        return (
          setcomapanyName(item.Company.companyName),
          setcompanyEmail(item.Company.Email),
          setphone(item.Company.Contact),
          setcity(item.Company.City),
          setEmailSend(!!item.invoices[0].isEmailSend || false),
          item.invoices.map((item: any) => {
            return (
              setTax(item?.additionalTax || item?.tax),
              setcustcompanyEmail(item.custEmail),
              setInvoiceDate(item.InvoiceDate),
              setcustphone(item.custContact),
              setBilladdress(item.BillAddress),
              setShipAddress(item.ShipAddress),
              setzipcode(item.zipcode),
              setcustomerName(item.companyName),
              setDiscount(item.discount),
              setNotes(item.notes),
              setAdditionalTax(item?.tax),
              setCustomerInfo({
                value: item?.customersID,
                label: item?.companyName
              })
            );
          })
        );
      });
    }
  };

  const SendEmail = async (id: number) => {
    swal({
      title: 'Are you sure ?',
      text: 'You want to send email to Customer ?',
      icon: 'warning',
      buttons: ['Cancel', 'Ok'],
      dangerMode: true,
      closeOnClickOutside: false,
      closeOnEsc: false
    }).then(async (response: any) => {
      if (response) {
        setLoading(true);
        const result = await apiResponseGenerator({
          dispatchFunction: dispatch,
          method: 'get',
          url: `saleitems/sendEmail/${id}`
        });
        if (result) {
          setLoading(false);
          swal({
            title: 'Success',
            text: 'Email sent successfully.',
            icon: 'success'
          });
        }
      }
    });
  };

  const SendQb = async (id: number) => {
    swal({
      title: 'Are you sure ?',
      text: 'You want to close this invoice ?',
      icon: 'warning',
      buttons: ['Cancel', 'Ok'],
      dangerMode: true,
      closeOnClickOutside: false,
      closeOnEsc: false
    }).then(async (response: any) => {
      if (response) {
        setLoading(true);
        const result = await apiResponseGenerator({
          dispatchFunction: dispatch,
          method: 'get',
          url: `quickbooks/add/${id}`
        });
        if (result) {
          setLoading(false);
          swal({
            title: 'Success',
            text: 'Invoice closed successfully.',
            icon: 'success'
          });
          navigate(-1);
        }
      }
    });
  };

  const handleInvoicesReopen = async (id: number) => {

    const modalResponse = await swal({
      title: 'Are you sure?',
      text: 'you want to re-open this invoice',
      buttons: {
        confirm: {
          text: 'Yes re-open',
          closeModal: true,
        },
        cancel: true,
      },
      closeOnEsc: false,
      closeOnClickOutside: false,
      dangerMode: true
    });

    if (modalResponse) {
      const api_response = await apiResponseGenerator({
        dispatchFunction: dispatch,
        method: 'put',
        url: `invoice/reOpenByID/${id}`,
        body: { status: 0 }
      });
      if (api_response) {
        const result = await swal({
          title: 'Success',
          text: 'Invoice no added successfully.',
          icon: 'success'
        });
        if (result) {
          navigate(-1)
        }
      }
    }
  }

  const PrintThePage = () => {
    setPrintBtn(false);
    if (!PrintBtn) {
      window.print();
    }
  };

  const PrintAllData = () => {
    setPrintBtn(true);
    if (PrintBtn) {
      window.print();
    }
  };

  const onChangeRate = async (
    e: any,
    invoiceIndex: number,
    mainArrayIndex: number
  ) => {
    const { value } = e.target;

    if (!invoicesIndexs.includes(mainArrayIndex)) {
      setInvoicesIndexs((perv) => [...perv, mainArrayIndex]);
    }

    if (!invoicesObjIndexs.includes(invoiceIndex)) {
      setInvoicesObjIndexs((perv) => [...perv, invoiceIndex]);
    }

    const InvoiceArray =
      invoiceData && invoiceData.map((obj: any) => obj.invoices);

    if (!InvoiceArray) return;
    let finalRespone = InvoiceArray.map((item: any, arrayIndex: number) => {
      if (arrayIndex === mainArrayIndex) {
        let FinalResult = item.map((objItems: any, objIndex: number) => {
          return objIndex === invoiceIndex
            ? { ...objItems, price: value }
            : objItems;
        });

        let TotalAmount = FinalResult.reduce((prev: any, current: any) => {
          return prev + +current.price * current.qty;
        }, 0);

        const newInvoiceData: any = invoiceData.map(
          (invoiceData: any, replaceIndex) => {
            return arrayIndex === replaceIndex
              ? {
                ...invoiceData,
                invoices: FinalResult,
                TotalAmount: TotalAmount
              }
              : invoiceData;
          }
        );

        const SubTotal = newInvoiceData.reduce((prev: any, current: any) => {
          return prev + +current.TotalAmount;
        }, 0);

        setSubTotalAmount(SubTotal);

        setInvoiceData(newInvoiceData);
      }
    });
  };

  const updateInvoice = async () => {
    let finalResponse: any = [];
    let result: any = swal({
      title: `Are you sure ?`,
      text: ' You want to provide free cases ?',
      icon: 'warning',
      buttons: [true, true],
      dangerMode: true,
      closeOnClickOutside: false,
    })
      .then(async (response: true) => {
        if (response !== null) {
          const invoice_mapping: any = invoiceData?.map(
            (data: any, index: number) => {
              if (invoicesIndexs.includes(index)) {
                return data?.invoices?.map(
                  (invoiceItem: any, invoice_Id: number) => {
                    if (invoicesObjIndexs.includes(invoice_Id)) {
                      let productObj: any = {
                        product_flavour_id: +invoiceItem?.product_flavour_id,
                        qty: +invoiceItem?.qty,
                        price: +invoiceItem?.price,
                        total_price: +invoiceItem?.price * +invoiceItem?.qty,
                      };
                      let response: any = {
                        sales_id: +invoiceItem?.sales_id,
                        grand_total: data.TotalAmount,
                        products: [productObj],
                      };

                      // console.log(finalResponse);

                      const CheckCondition = finalResponse?.find(
                        (obj: any) => obj.sales_id === +invoiceItem?.sales_id
                      );

                      if (CheckCondition) {
                        finalResponse[0].products = [
                          ...finalResponse[0].products,
                          productObj
                        ];
                      }
                      if (!CheckCondition) {
                        finalResponse.push(response);
                      }

                      return;
                    }
                  }
                );
              }
            }
          );

          const response_data = {
            created_at: moment(InvoiceDate).format('YYYY-MM-DD HH:MM:SS'),
            invoice_id: id,
            notes: notes,
            discount: discount,
            additionalTax: Tax,
            total_amount: `$${SubTotalAmount}`,
            sales: finalResponse && finalResponse,
          };
          setLoading(true);
          const api_response = await apiResponseGenerator({
            dispatchFunction: dispatch,
            method: 'post',
            url: `saleitems/updateInvoice`,
            body: response_data
          });

          if (api_response) {
            setLoading(false);
            swal({
              title: 'Success',
              text: 'invoice updated successfully.',
              icon: 'success',
              closeOnClickOutside: false,
            });
            setIsDateEdit(false)
            ViewInvoice(id);
          }
          return 'success';
        }
      })
      .catch((err: any) => {
        console.log('err', err);
      });
  };

  if (Loading) {
    return (
      <div>
        <div
          className="d-flex justify-content-center"
          style={{ marginTop: '250px' }}
        >
          <ScaleLoader color="#007bff" height={40} />
        </div>
      </div>
    );
  }

  const updateCustomerName = async () => {
    const api_response = await apiResponseGenerator({
      dispatchFunction: dispatch,
      method: 'put',
      url: `invoice/updateCustomerNameByInvoiceId/${id}`,
      body: {
        customer_id: customerInfo?.value
      }
    });

    if (api_response) {
      swal({
        title: 'Success',
        text: 'invoice updated successfully.',
        icon: 'success',
        closeOnClickOutside: false,
      }).then(() => {
        SetIsCompanyNameModal(false)
      });
      ViewInvoice(id);
    }

  }

  return (
    <div>
      <div className='sticky-top bg-white'>
        <ContentHeader title={`Invoice # ${state?.invoice_id || id}`} />
        {isCompanyNameModal && <SalesTableModal
          item={customerInfo}
          userIndex={0}
          options={customers}
          onSave={() => {
            updateCustomerName()
          }}
          onHide={() => {
            SetIsCompanyNameModal(false)
          }
          }
          callBack={setCustomerInfo}
        />}

        {state?.id ? (
          <div className="row mb-2 d-flex col-lg print">
            <div
              className="col d-flex justify-content-end"
              style={{ marginRight: '-20px' }}
            >
              <div className="mx-2">
                <AppButton
                  children={'Change Customer Name'}
                  className="btn-md mb-3 "
                  onClick={() => SetIsCompanyNameModal(true)}
                />
              </div>
              <div className="mx-2">
                <AppButton
                  children={'Packing slip'}
                  className="btn-md mb-3 "
                  onClick={() => PrintThePage()}
                />
              </div>

              <div className="mx-2">
                <AppButton
                  children={'Print invoice'}
                  className="btn-md mb-3 "
                  onClick={() => PrintAllData()}
                />
              </div>

              <div>
                <AppButton
                  children={isEmailSend ? 'Sent to customer' : 'Send to customer'}
                  onClick={() => {
                    SendEmail(id);
                  }}
                  className="btn-md mb-3 mr-3 btn btn-info"
                />
              </div>
              {state?.status === 'Closed' ? (
                <div>
                  <AppButton
                    children={'Re-open this invoice'}
                    onClick={() => {
                      handleInvoicesReopen(id)
                    }}
                    className="btn-md mb-3 btn btn-secondary"
                  />
                </div>
              ) : (
                <div>
                  <AppButton
                    children={'Close this invoice'}
                    onClick={() => {
                      SendQb(id);
                    }}
                    className="btn-md mb-3 btn btn-secondary"
                  />
                </div>
              )}
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>


      <div className="offset-xl-2 col-xl-8 col-lg-12 col-md-12 col-sm-12 col-12 padding">
        <div className="card">
          <div className="card-header p-4">
            <div
              className="float-left d-flex justify-content-between"
              style={{ marginTop: '-20px' }}
            >
              <div>
                <img
                  src="/assets/logo.svg"
                  alt="NOW-POSH"
                  className="w-100"
                  height="100px"
                />
              </div>
              <div className="ml-3 mt-4">
                <b>Company Name:</b> {comapanyName}
                <br />
                <b>City:</b> {city}
                <br />
                <b>Phone:</b> {phone}
                <br />
                <b>Email:</b> {companyEmail}
              </div>
            </div>
            <div className="float-right">
              {' '}
              <h3 className="mb-0">Invoice #{state && state.invoice_id}</h3>
              <h4 className="mb-0">Grn #{id}</h4>
              <div className='d-inline-flex justify-content-between mt-1'>
                <b>Date</b>:   {
                  !!isDateEdit ?
                    <span className='mt-0 realtive'>
                      <input style={{ width: 100 }} type="date" className='mt-0' value={moment(InvoiceDate).format('YYYY-MM-DD')} onChange={(v) => setInvoiceDate(v.target.value)} />
                      <span onClick={() => setIsDateEdit(false)} style={{ cursor: 'pointer' }} className='mt-0 ml-1'>X</span>
                    </span>
                    :
                    <span style={{ cursor: 'pointer' }} className='mt-0 pe-auto' onClick={() => { setIsDateEdit(true) }}>
                      {moment(InvoiceDate).format('MM/DD/YYYY')}
                    </span>}
              </div> <br />
              <b>Terms</b> Due on receipt
            </div>
          </div>
          <div className="card-body">
            <div className="row mb-4">
              <div className="col-sm-6">
                <h5 className="mb-3"> BILL TO:</h5>
                <h3 className="text-dark mb-1">{customerName.toLocaleUpperCase()}</h3>
                <div></div>
                <div>
                  Address: {BillAddress}, {zipcode}
                </div>
                <div> Email: {custcompanyEmail}</div>
                <div> Phone: {custphone}</div>
              </div>
              <div className="col-sm-6">
                <h5 className="mb-3"> SHIP TO:</h5>
                <h3 className="text-dark mb-1">{customerName.toLocaleUpperCase()}</h3>
                <div></div>
                <div>
                  Address: {ShipAddress}, {zipcode}
                </div>
                <div> Email: {custcompanyEmail}</div>
                <div> Phone: {custphone}</div>
              </div>
            </div>
            <div className="table-responsive-sm">
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th className="center">#</th>
                    <th>ACTIVITY</th>
                    <th>DESCRIPTION</th>
                    <th className="center">
                      <div className={PrintBtn ? '' : 'table-column-to-hide'}>
                        RATE
                      </div>
                    </th>
                    <th className="right">QTY</th>
                    <th className="right">VOLUME</th>
                    <th className="right">
                      <div className={PrintBtn ? '' : 'table-column-to-hide'}>
                        AMOUNT
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {invoiceData &&
                    invoiceData.map((item: any, mainArrayIndex: number) => {
                      return (
                        <>
                          {item.invoices.map(
                            (item: any, invoiceIndex: number) => {
                              let total_amount =
                                item.qty * +item.price;
                              return (
                                <tr key={invoiceIndex}>
                                  <td className="center">{invoiceIndex + 1}</td>
                                  <td className="left strong">
                                    {`${item.ShortCode} ${item.Barcode === null ? " " : item.Barcode}`}
                                  </td>
                                  <td className="left">{`${item.productName} (${item.flavourName})`}</td>
                                  <td className="center ">
                                    <div
                                      style={{ width: '50%', margin: 'auto' }}
                                      className={
                                        PrintBtn ? '' : 'table-column-to-hide'
                                      }
                                    >
                                      <input
                                        value={item.price}
                                        disabled={false}
                                        onChange={(e: any) =>
                                          onChangeRate(
                                            e,
                                            invoiceIndex,
                                            mainArrayIndex
                                          )
                                        }
                                        type={'number'}
                                        placeholder={'Rate'}
                                        className={`form-control inputClass hideNumber_controls text-center`}
                                      />
                                    </div>
                                  </td>
                                  <td className="right">{item.qty}</td>
                                  <td className="right">
                                    {item.volume ? item.qty * item.volume : '-'}
                                  </td>
                                  <td
                                    className={
                                      PrintBtn
                                        ? 'right'
                                        : 'right table-column-to-hide'
                                    }
                                  >
                                    {total_amount.toLocaleString()}
                                  </td>
                                </tr>
                              );
                            }
                          )}
                          <tr>
                            <td className="right"></td>
                            <td className="right"></td>
                            <td className="right"></td>
                            <td className="right"></td>
                            <td className="right">S/T Qty {toFixedWithoutRounding(item.TotalQty, 2)}</td>
                            {item.TotalVolume === 0 ? (
                              <td className="right"></td>
                            ) : (
                              <td className="right">
                                S/T Volume {toFixedWithoutRounding(item.TotalVolume, 2)}
                              </td>
                            )}
                            <td
                              className={
                                PrintBtn
                                  ? 'right'
                                  : 'right table-column-to-hide'
                              }
                            >
                              S/T Amount {toFixedWithoutRounding(item.TotalAmount, 2)}
                            </td>
                          </tr>
                        </>
                      );
                    })}
                </tbody>
              </table>
            </div>
            <div className="row">
              <div className="col-lg-4 col-sm-5">
                <div className="ml-2 mt-4">
                  <p>Thank you for your bussiness.</p>
                  <strong className="text-dark">Notes</strong>
                  <textarea
                    style={{ resize: 'none' }}
                    rows={3}
                    disabled={false}
                    onChange={(e) => {
                      setNotes(e.target.value)
                    }}
                    className="form-control mt-1"
                    id="notes"
                    placeholder="Notes"
                    defaultValue={notes || ''}
                  />
                </div>
                <div className="ml-2" style={{ marginTop: '6.5pc' }}>
                  <b>___________________________</b>
                  <br />
                  <p>
                    <b>Customer's Signature</b>
                  </p>
                </div>
              </div>
              <div
                className={
                  'col-lg-4 col-sm-5 ml-auto'
                }
              >
                <table className="table table-clear">
                  <tbody>
                    <tr>
                      <td className="left">
                        <strong className='text-dark'>TOTAL QTY</strong>
                      </td>
                      <td className="right">{SubTotalQty && toFixedWithoutRounding(SubTotalQty, 2)}</td>
                    </tr>
                    <tr className={PrintBtn ? '' : ' table-column-to-hide'}>
                      <td className="left">
                        <strong className="text-dark">TOTAL Volume</strong>
                      </td>
                      <td className="right">{SubTotalVolume && toFixedWithoutRounding(SubTotalVolume, 2)}</td>
                    </tr>
                    <tr className={PrintBtn ? '' : ' table-column-to-hide'}>
                      <td className="left">
                        <strong className="text-dark">TAX</strong>
                      </td>
                      <td className="right">
                        <div style={{ width: '50%', margin: 'auto' }}>

                          <input
                            value={isTaxEdit ? Tax : SubTotalAmount && toFixedWithoutRounding(SubTotalAmount * Tax, 2)}
                            disabled={false}
                            onFocus={() => {
                              setIsTaxEdit(true)
                            }}
                            onBlur={() => {
                              setIsTaxEdit(false)
                            }}
                            onChange={(e: any) => {
                              setTax(e.target.value)
                            }
                            }
                            type={'number'}
                            placeholder={'Tax'}
                            className={`form-control inputClass hideNumber_controls text-center`}
                          />
                          {/* {SubTotalAmount && toFixedWithoutRounding(SubTotalAmount * Tax , 2)} */}
                        </div>
                      </td>
                    </tr>
                    <tr className={PrintBtn ? '' : ' table-column-to-hide'}>
                      <td className="left">
                        <strong className="text-dark">TOTAL</strong>
                      </td>
                      <td className="right">
                        {SubTotalAmount && toFixedWithoutRounding(SubTotalAmount, 2)}
                      </td>
                    </tr>
                    <tr className={PrintBtn ? '' : ' table-column-to-hide'}>
                      <td className="left">
                        <strong className="text-dark">Discount</strong>
                      </td>
                      <td className="center">
                        <div
                          style={{ width: '50%', margin: 'auto' }}
                          className={
                            PrintBtn ? '' : 'table-column-to-hide'
                          }
                        >
                          <input
                            value={discount || ''}
                            disabled={false}
                            onChange={(e: any) => {
                              setDiscount(e.target.value)
                            }
                            }
                            type={'number'}
                            placeholder={'discount'}
                            className={`form-control inputClass hideNumber_controls text-center`}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr className={PrintBtn ? '' : ' table-column-to-hide'}>
                      <td className="left">
                        <strong className="text-dark">TOTAL DUE</strong>
                      </td>
                      <td className="right">
                        <strong className="text-dark">
                          ${SubTotalAmount && (toFixedWithoutRounding(((SubTotalAmount * Tax) + SubTotalAmount - discount), 2)).toLocaleString()}
                        </strong>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className='return-police row'>
                <h5 style={{fontSize: 14, fontWeight: 600}} className='mb-1'>Return & Exchange Policy : </h5>
                <p style={{fontSize: 14, width: '100%'  }}>
                  We do not accept returns or exchanges after 2 months from the date of purchase. To be eligible for a return or exchange, the product must be in its original, unopened packaging. If you need to exchange an item due to a defect or damage, please contact us within the return period to arrange for an exchange.
                </p>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-end print">
          <AppButton
            children={'Update'}
            disabled={false}
            className="ml-3 mb-3"
            onClick={() => {
              updateInvoice();
            }}
          />

          <AppButton
            children={'Go Back'}
            className="ml-3 mb-3 btn btn-light "
            onClick={() => {
              navigate(-1);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default CommonTableSingleFlavor;
