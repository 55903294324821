import React, { useEffect, useState } from "react";
import ContentHeader from "../content-header/ContentHeader";
import PoshInputs from '@app/components/Forms/InputForms/Inputs'
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate,useParams } from "react-router-dom";
import { ActiveDropdown } from '@app/components/dropdown/ActiveDropdown'
import swal from 'sweetalert'
import axios from 'axios'
import ProgressLoader from "../ProgressLoader/ProgressLoader";
import { progressLoader } from "@app/store/reducers/ui";
import AppButton from "../button/Button";
import { BASE_URL } from "../constants/app";
import { ScaleLoader } from "react-spinners";


const UpdateFlavours = (props:any) => {
  const location:any=useLocation()
  const flavour=location.state
  const dispatch=useDispatch()
  const token=useSelector((state:any)=>{return state.auth?.token})
  const [productInput,setProductInput]:any=useState(flavour.flavourName)
  const [status,setStatus]:any =useState()
  const [disabled,setDisabled]:any=useState(true)
  const [loading,setLoading]:any=useState<boolean>()
 
  let navigate:any = useNavigate()
  var regex =  /^\s*$/ ; 
 
  window.addEventListener('popstate', function(event) {navigate("/admin/products/flavors")})
  
  const config = {
    Accept: 'application/json',
    Authorization: `Bearer ${token}`,
  };
  useEffect(()=>{
   if(productInput!=undefined||null||""){
    setDisabled(false)
   } 
   if(regex.test(productInput)==true){
    setDisabled(true)
   }
  },[productInput])

const modal = async () => {
 
    const model = await swal({
      title: "Are you sure ?",
      text: `You want to update flavor ?`,
      icon: "warning",
      buttons: [true,true],
      dangerMode: true,
      closeOnClickOutside: false
    })
    if(model) {
      setLoading(true)
        dispatch(progressLoader(25))
        try {  
          const api_response = await axios({
            method:"post",
            url:  `${BASE_URL}flavors/update/${flavour?.id}`,
            headers:config,
            data:{
              flavourName:productInput,
              status:status
            },
            onUploadProgress:progressEvent => {
              let progressB = (progressEvent.loaded / progressEvent.total) * 100;
              dispatch(progressLoader(progressB))
              // setProgress(progressB)
            }
          }) 
          if(api_response) {
              setLoading(false)
              swal({
                text:"Flavor updated successfully.",
                icon: "success", 
              });
            }
            navigate(-1)
    }
    catch (error) {
      swal("Flavour has failed to add !", {
        icon: "failed",
      });
    }
  }
}

if(loading){
  return(<div>
    <div className="d-flex justify-content-center"  >
        <ScaleLoader color="#007bff" height={40} />
    </div>
  </div> )
}
  return (
    <div>
        <ContentHeader title="Update Flavor" />
        <section className=" bg-white p-4 rounded m-3">
          <div className="inputWrapper">
            <PoshInputs value={productInput}  onChange={(e:any)=>{setProductInput(e.target.value)}} title='Flavor Name' type={"text"} readOnly={true} />
            <ActiveDropdown defaultValue={flavour.status} onChange={(e:any)=>{ e.target.value==1? setStatus(1) : setStatus(2)             
             }}   />
          </div>          
          <div className="d-flex justify-content-end" style={{marginRight:"10px"}}>
            <AppButton children={"Cancel"} onClick={()=>{navigate(-1)}} type="button" className="btn btn-light mr-3"/>
            <AppButton children={"Update"}  disabled={disabled} className="btn btn-primary" onClick={()=>{modal()}}/>
          </div>
       </section>
    </div>
  );
};

export default UpdateFlavours;

