import React, {useEffect, useState} from 'react';
import './Table.css';
import swal from 'sweetalert';
import {useDispatch, useSelector} from 'react-redux';
import Select from 'react-select';
import {ScaleLoader} from 'react-spinners';
import {useNavigate} from 'react-router-dom';
import LocalStorageFunctions from '../../utils/LocalStorageFuntions';
import {LocalStorage_Keys} from '@app/enum/LocalStorage_Keys';
import _ from 'lodash';
import {
  SalesTableProps,
  ICustomerData,
  IFlavorData
} from '../../interface/Sales';
import apiResponseGenerator from '@app/utils/apiResponseGenerator';
import SalesTableModal from '../Modal/SaleTableModal';
import { getShortName } from '@app/utils/helpers';
import { toFixedWithoutRounding } from '@app/utils/calculationFun';

const SalesTable = ({
  productFlavor,
  customerData,
  halfName,
  productInfo,
  salesPrice,
  inComingSales
}: SalesTableProps) => {
  const navigate = useNavigate();
  const [flavors, setFlavors] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const localStorageFunctions = new LocalStorageFunctions();

  // user states
  const [allCustomerData, setAllCustomerData] = useState<any>(null);
  const [selectedCustomerData, setSelectedCustomerData] = useState<any>([{}]);
  const [isFocused, setIsFocused]: any = useState(null);
  const [modalVisible, setModalVisible]:any = useState(false);
  const [selectedItem, setSelectedItem]:any = useState(null);
  const [companyNameRowIndex, setCompanyNameRowIndex]:any = useState(null);
  const dispatch = useDispatch();

  // creating options
  const allCustomerData_forDropdown = allCustomerData?.map((ctd: any) => {
    return {
      label: `${ctd?.companyName}` || ctd?.label,
      value: +ctd?.id,
      incentive: ctd?.incentive,
      invoice: ctd?.invoice
    };
  });

  const getTheInvoices = async (id: number) => {
    if (!id) return;
    // calling the api
    setSelectedCustomerData([{}]);
    setLoading(true);
    const api_response = await apiResponseGenerator({
      dispatchFunction: dispatch,
      method: 'get',
      url: `sales/get/${id}`
    });

    if (api_response) {
      setLoading(false);
    }
    const result = await api_response?.data?.salesData;
    let final_res;

    if (result?.length > 0) {
      final_res = result?.map((data: any) => {
        let NewSalesData = [...data?.sales];
        productFlavor.forEach((productFl: any) => {
          let SalesIdsArr: any[];
          if (data?.sales?.length < productFlavor?.length) {
            SalesIdsArr = [
              ...data?.sales?.map((sd: any) => sd?.product_flavour_id)
            ];

            if (!SalesIdsArr.includes(productFl.id)) {
              NewSalesData.push({
                ...productFl
              });
            }
          }
        });
        return {
          customer_id: data?.customer_id,
          customerName: `${data?.custFirstName} ${data?.custFirstName}`,
          incentive: data.incentive ? data.incentive : 0,
          grand_total: data?.grand_total,
          invoice_id: data?.invoice_id,
          flavors: NewSalesData,
          cust_status: 'Generated'
        };
      });
    }
    if (result?.length > 0) {
      setSelectedCustomerData(final_res);
    }
    return final_res;
  };

  useEffect(() => {
    setFlavors(productFlavor);
    // get the customer Data
    setAllCustomerData(customerData);
  }, [productFlavor, customerData]);

  useEffect(() => {
    getTheInvoices(productInfo?.id);
  }, [productFlavor]);

  // **************Validation functions****************
  const checkIfCustomerExceeds = () => {
    if (selectedCustomerData?.length >= allCustomerData?.length) {
      swal('Error', 'All customers are already selected', 'error');
      return true;
    }
    return false;
  };

  const AddNewCustomerRecord = (e: any) => {
    const check = checkIfCustomerExceeds();
    if (check) return;
    setSelectedCustomerData((oldData: any) => [...oldData, {}]);
  };

  // ******************* actions *****************

  const changeCustomerAutocomplete = (
    selected_user?: any,
    rowIndex?: number | string
  ) => {
    // let selected_user: any;
    let is_userExists: boolean = false;

    // check if user already exists in select User
    const check_userExists = selectedCustomerData?.forEach(
      (select_data: any) => {
        if (
          +select_data?.customer_id === +selected_user?.value ||
          +select_data?.id === +selected_user?.value
        ) {
          is_userExists = true;
          swal({
            title: 'Alert',
            text: `${selected_user.label} already exists`,
            icon: 'warning',
            buttons: [false, true],
            dangerMode: true,
            closeOnClickOutside: false
          });
        }
      }
    );
    if (is_userExists) {
      // setGetSelectUser(null);
      return;
    }
    // setGetSelectUser(selected_user);

    const _cust_data = {
      ...selected_user,
      id: selected_user?.value,
      customer_id: selected_user?.value,
      flavors: [...(flavors || {})]
    };

    // select / change user in array for final response
    const change_cust_data = selectedCustomerData?.map(
      (oldData: any, index: number) =>
        index === rowIndex ? {..._cust_data} : oldData
    );

    setSelectedCustomerData(change_cust_data);
    return 'success';
  };

  const handleRowClick = (item:any,userIndex:string) => {
    setCompanyNameRowIndex(userIndex)
    setSelectedItem(item);
    setModalVisible(true);
  };

  const UpdateFlavorData = (
    event: any,
    flavour_index: number,
    user_index: number | string,
    flavourFld?: any
  ) => {
    const currentValue = +event?.target?.value;
    if (isNaN(currentValue)) return;

    const valueToUpdate = +currentValue;

    const updatedFlavors_Data = selectedCustomerData?.map(
      (cust_data: any, cust_index: number): void => {
        // check ramming flavours
        const update_nestedArr = cust_data['flavors']?.map(
          (d: any, index: number) => {
            if (index === +flavour_index) {
              if (+valueToUpdate > d?.qtyReceived) {
                swal({
                  title: 'Not enough available stock',
                  text: '',
                  icon: 'error',
                  dangerMode: true
                });
                return {
                  ...d,
                  [flavourFld!]: +d?.qtyReceived
                };
              }
              return {
                ...d,
                [flavourFld!]: +valueToUpdate
              };
            } else {
              return d;
            }
          }
        );

        return cust_data && cust_index === +user_index
          ? {
              ...cust_data,
              flavors: update_nestedArr
            }
          : cust_data;
      }
    );

    const fl_totalQty: any =
      updatedFlavors_Data?.[+user_index]?.flavors?.[flavour_index]?.qtyReceived;

    const totalFlavoursSold = getFlavourSales(
      updatedFlavors_Data,
      flavour_index
    );
    const calculation = +fl_totalQty - +totalFlavoursSold;

    const check_updatedFlavors_Data = selectedCustomerData?.map(
      (cust_data: any, cust_index: number): void => {
        const update_nestedArr = cust_data['flavors']?.map(
          (d: any, index: number) => {
            if (index === +flavour_index) {
              return {
                ...d,
                [flavourFld!]: 0
              };
            } else {
              return d;
            }
          }
        );

        return cust_data && cust_index === +user_index
          ? {
              ...cust_data,
              flavors: update_nestedArr
            }
          : cust_data;
      }
    );
    if (+calculation < 0) {
      swal({
        title: 'Not enough available stock',
        text: '',
        icon: 'warning',
        dangerMode: true,
        closeOnClickOutside: false
      });
      setSelectedCustomerData(check_updatedFlavors_Data);
    } else {
      setSelectedCustomerData(updatedFlavors_Data);
    }
  };

  const UpdateRateData = (event: any, userIndex: number | string) => {
    const {name, value} = event?.target;
    // validations
    // 1) check if the value is alphabet do update unless it a number
    // if (isNaN(+value)) {
    //   return;
    // }

    const Update_selectedCustomer = selectedCustomerData?.map(
      (cust_data: any, cust_index: number) => {
        const customer_updated_fl = cust_data?.flavors?.map((fl_data: any) => {
          return {
            ...fl_data,
            price: +value
          };
        });
        return +cust_index === +userIndex
          ? {
              ...cust_data,
              product_salePrice: +value,
              flavors: customer_updated_fl
            }
          : cust_data;
      }
    );
    setSelectedCustomerData(Update_selectedCustomer);
    return 'success';
  };

  // ***********Button Events****************
  const CreateExisting = async (customerId: number, customer_index: number) => {
    const model = await swal({
      title: 'Wanna Create Existing?',
      text: 'Are you sure to generate ? ',
      icon: 'warning',
      buttons: [true, true],
      closeOnClickOutside: false
    });
    if (model) {
      const GET_SelectedcustomerData = selectedCustomerData?.filter(
        (sl_custData: any) =>
          +sl_custData?.customer_id === +customerId ||
          +sl_custData?.id === +customerId
      );
      const sales_data_mapping = GET_SelectedcustomerData?.map((data: any) => {
        return data?.flavors?.map((fl_data: any) => {
          return {
            product_flavour_id: +fl_data?.product_flavour_id || +fl_data?.id,
            qty: +fl_data?.allocated
              ? +fl_data?.allocated
              : +fl_data?.qty
              ? +fl_data?.qty
              : 0,
            price: +fl_data?.price,
            total_price: +fl_data?.allocated
              ? +fl_data?.allocated
              : +fl_data?.qty * +fl_data?.price
              ? +fl_data?.qty * +fl_data?.price
              : 0 * +fl_data?.price
          };
        });
      });

      const response_data = {
        product_id: productInfo?.id,
        sales: [
          {
            total_cases: GET_totalCasesSold(customer_index) || 0,
            grand_total: GET_grandTotal(customer_index),
            flavors: sales_data_mapping[0]
          }
        ]
      };
      localStorageFunctions.set(
        LocalStorage_Keys.InvoiceResponse,
        response_data
      );
      localStorageFunctions.set(LocalStorage_Keys.CurrentProductInfo, {
        id: productInfo?.id,
        label: productInfo?.label,
        value: productInfo?.value
      });
      navigate(`/admin/sales-and-invoices/existing-Invoice/${customerId}`);
    }
  };

  const UpdateSalesData = async (user_index: number | string) => {
    // making the response
    const selecteCust_dataForResponse = selectedCustomerData?.filter(
      (_: any, index: number) => index === +user_index
    );
    let invoiceID: number;
    let grandTotal: number;
    let totalCases: number;
    const sales_data_mapping = selecteCust_dataForResponse?.map(
      (data: any, index: number) => {
        invoiceID = +data?.invoice_id || +data?.cust_invoice_id;
        grandTotal = GET_grandTotal(+user_index);
        totalCases = GET_totalCasesSold(+user_index) || 0;
        return data?.flavors?.map((fl_data: any) => {
          return {
            product_flavour_id: +fl_data?.product_flavour_id || +fl_data.id,
            qty: +fl_data?.allocated || +fl_data?.qty || 0,
            price: +fl_data?.price || 0,
            total_price:
              +fl_data?.allocated || +fl_data?.qty * +fl_data?.price || 0
          };
        });
      }
    );
    const response_data = {
      product_id: productInfo?.id,
      invoice_id: invoiceID!,
      grand_total: grandTotal!,
      total_cases: totalCases!,
      flavors: sales_data_mapping[0]
    };
    try {
      setLoading(true);
      const api_response = await apiResponseGenerator({
        dispatchFunction: dispatch,
        method: 'post',
        url: `sales/update`,
        body: response_data
      });

      if (api_response) {
        setLoading(false);
        swal({
          title: 'success',
          text: 'invoice updated successfully',
          icon: 'success'
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const GenerateSalesData = async (user_index: number | string) => {
    // making the response
    const selecteCust_dataForResponse = selectedCustomerData?.filter(
      (_: any, index: number) => index === +user_index
    );
    // flavors
    const sales_data_mapping = selecteCust_dataForResponse?.map(
      (data: any, index: number) => {
        return {
          customer_id: data?.id,
          grand_total: GET_grandTotal(+user_index),
          total_cases: GET_totalCasesSold(+user_index),
          flavors: data?.flavors?.map((fl_data: any) => {
            return {
              product_flavour_id: +fl_data?.id,
              qty: fl_data?.allocated || fl_data?.qty || 0,
              price: +fl_data?.price || 0,
              total_price:
                +fl_data?.allocated || +fl_data?.qty * +fl_data?.price || 0
            };
          })
        };
      }
    );
    const response_data = {
      product_id: productInfo?.id,
      sales: sales_data_mapping
    };
    setLoading(true);
    const api_response = await apiResponseGenerator({
      dispatchFunction: dispatch,
      method: 'post',
      url: `invoice/add`,
      body: response_data
    });

    if (api_response) {
      setLoading(false);
      // reload()
      swal({
        title: 'Success',
        text: 'invoice generated successfully',
        icon: 'success'
      });
      const updated_seletedCustomer = selectedCustomerData?.map(
        (data: any, index: number) => {
          if (index === +user_index) {
            return {
              ...data,
              cust_status: 'generated',
              cust_invoice_id: api_response?.data?.invoice_id
            };
          } else {
            return data;
          }
        }
      );
      setSelectedCustomerData(updated_seletedCustomer);
    }
    return 'success';
  };

  // *********Helper Functions**********
  const GET_grandTotal = (customerId: number) => {
    const check = (selectedCustomerData as ICustomerData[])
      .at(customerId)
      ?.flavors?.map((data): any => {
        return +data?.allocated
          ? +data?.allocated
          : +data?.qty
          ? +data?.qty
          : 0;
      });
    let result: number = 0;
    const fl =
      selectedCustomerData[customerId]?.flavors?.map((data: any) => {
        result = check?.reduce((value: number, state: number) => {
          return value + state * data?.price;
        }, 0);
      }) || 0;
    return result;
  };

  const GET_totalCasesSold = (customerId: number): number => {
    const check = (selectedCustomerData as ICustomerData[])
      .at(customerId)
      ?.flavors?.map((data): any => {
        return +data?.allocated
          ? +data?.allocated
          : +data?.qty
          ? +data?.qty
          : 0;
      });
    return (
      check?.reduce((value: number, state: number) => {
        return value + state;
      }, 0) || 0
    );
  };

  const getFlavourSales = (Arr: any[], flavour_index: number) => {
    const Fl_arr = Arr?.map((customerData: ICustomerData) => {
      if (isNaN(customerData?.flavors?.length)) {
        return 0;
      }
      const sumOfFlavors = customerData?.flavors?.reduce(
        (value: number, state: IFlavorData, index: number) => {
          return index === +flavour_index
            ? +value + +state?.allocated
              ? +state?.allocated
              : +state?.qty
              ? +state?.qty
              : 0
            : value;
        },
        0
      );
      return +sumOfFlavors;
    });
    const totalFlavoursSold = _.sum(Fl_arr);
    return +totalFlavoursSold;
  };

  const remainingFlavours = (fl_index: number, flavourSold?: number) => {
    const fl_totalQty = flavors?.[fl_index]?.qtyReceived;
    if (!flavourSold) {
      flavourSold = getFlavourSales(selectedCustomerData, fl_index);
    }
    const calculation = +fl_totalQty - +flavourSold;
    const remainingFlavours = calculation < 0 ? 0 : calculation;
    return remainingFlavours;
  };

  const getIncomingCount = (index: number) => {
    return inComingSales[index] ? inComingSales[index].qty : 0;
  };

  const getAllocatedTotalCount = (array: ICustomerData[]) => {
    const Fl_arr = array?.map((customerData: ICustomerData) => {
      // console.log({flavors: customerData?.flavors});
      if (isNaN(customerData?.flavors?.length)) {
        return 0;
      }

      const sumOfFlavors = customerData?.flavors?.reduce(
        (value: number, state: IFlavorData) => {
          const sumWhere = +state?.allocated ? +state?.allocated : +state?.qty;
          return +value + +sumWhere || 0;
        },
        0
      );
      return +sumOfFlavors;
    });
    // console.log({Fl_arr});
    const totalFlavoursSold = _.sum(Fl_arr);
    return +totalFlavoursSold;
  };
  
  const getTheRemainingTotalCount = () => {
    const sold = getAllocatedTotalCount(selectedCustomerData);
    const flQtyReceived = flavors?.map((item: any) => {
      return item.qtyReceived;
    });

    const remaining = flQtyReceived?.reduce(
      (accumulator: any, currentValue: any) => {
        return +accumulator + +currentValue;
      },
      0
    );

    return +remaining - +sold;
  };

  const getIncomingShippingTotalCount = () => {
    if (inComingSales.length === 0) {
      return 0;
    }

    const sum = inComingSales.reduce((a, c) => {
      return a + (c.qty || 0);
    }, 0);

    return sum;
  };
  
  return loading ? (
    <div>
      <div
        className="d-flex justify-content-center"
        style={{alignItems: 'center'}}
      >
        <ScaleLoader color="#007bff" height={40} />
      </div>
    </div>
  ) : (
    <>
      <table className="Table freeze-table" style={{maxHeight: '1200px', minHeight: '700px'}}>
        <tr className=" Table_thead bg-primary text-capitalize">
          <th className="freeze-col" style={{backgroundColor: '#007bff'}}>
            flavors name
          </th>
          <th
            className="freeze-col-input"
            style={{left: '16rem', backgroundColor: '#007bff'}}
          ></th>
          <th
            className="freeze-col-input"
            style={{left: '20rem', backgroundColor: '#007bff'}}
          ></th>
          {flavors?.map(({flavourName}: any, index: number) => {
            return <th key={index}>
               <span data-toggle="tooltip" data-placement="top" title={flavourName}>
                  {getShortName(flavourName)}
               </span>
              </th>;
          })}
          <th>Total</th>
          <th style={{backgroundColor: '#007bff'}}></th>
          <th style={{backgroundColor: '#007bff'}}></th>
          <th style={{backgroundColor: '#007bff'}}></th>
          <th style={{backgroundColor: '#007bff'}}></th>
          <th style={{backgroundColor: '#007bff'}}></th>
          <th style={{backgroundColor: '#007bff'}}></th>
          <th style={{backgroundColor: '#007bff'}}></th>
          <th style={{backgroundColor: '#007bff'}}></th>
          <th style={{backgroundColor: '#007bff'}}></th>
        </tr>

        {/* total */}
        <tr>
          <th className="text-capitalize freeze-col">total</th>
          <th className="freeze-col-input" style={{left: '16rem'}}></th>
          <th className="freeze-col-input" style={{left: '20rem'}}></th>
          {flavors?.map(({qtyReceived}: any, index: number) => {
            return (
              <>
                <td key={index} className="Table_text">
                  { toFixedWithoutRounding(qtyReceived, 2) || 0}
                </td>
              </>
            );
          })}

          {/*  total of total */}
          <td className="Table_text">
            {flavors?.reduce((accumulator: any, currentValue: any) => {
              return accumulator + currentValue.qtyReceived || 0;
            }, 0).toFixed(2)}
          </td>
          <th className="bg-freeze-color"></th>
          <th className="bg-freeze-color"></th>
          <th className="bg-freeze-color"></th>
          <th className="bg-freeze-color"></th>
          <th className="bg-freeze-color"></th>
          <th className="bg-freeze-color"></th>
          <th className="bg-freeze-color"></th>
          <th className="bg-freeze-color"></th>
          <th className="bg-freeze-color"></th>
        </tr>

        {/* allocated */}
        <tr>
          <th className="text-capitalize freeze-col">allocated</th>
          <th className="freeze-col-input" style={{left: '16rem'}}></th>
          <th className="freeze-col-input" style={{left: '20rem'}}></th>
          {flavors?.map(({qtyAllocated}: any, index: number) => {
            return (
              <td key={index} className="Table_text">
                {toFixedWithoutRounding(getFlavourSales(selectedCustomerData, index), 2) || 0}
              </td>
            );
          })}

          {/* total of allocated */}
          <td className="Table_text">
            {toFixedWithoutRounding(getAllocatedTotalCount(selectedCustomerData), 2) || 0}
          </td>
          <th className="bg-freeze-color"></th>
          <th className="bg-freeze-color"></th>
          <th className="bg-freeze-color"></th>
          <th className="bg-freeze-color"></th>
          <th className="bg-freeze-color"></th>
          <th className="bg-freeze-color"></th>
          <th className="bg-freeze-color"></th>
          <th className="bg-freeze-color"></th>
          <th className="bg-freeze-color"></th>
        </tr>

        {/* available */}
        <tr style={{backgroundColor: '#55595d', color: 'white'}}>
          <th
            className="text-capitalize freeze-col"
            style={{backgroundColor: '#55595d'}}
          >
            available
          </th>
          <th
            className="freeze-col-input"
            style={{left: '16rem', backgroundColor: '#55595d'}}
          ></th>
          <th
            className="freeze-col-input"
            style={{left: '20rem', backgroundColor: '#55595d'}}
          ></th>
          {flavors?.map(({qtyAllocated}: any, index: number) => {
            return (
              <td key={index} className="Table_text">
                {toFixedWithoutRounding(remainingFlavours(index), 2) || 0}
              </td>
            );
          })}

          <td className="Table_text">{getTheRemainingTotalCount().toFixed(2) || 0}</td>
          <th className="bg-freeze-color"></th>
          <th className="bg-freeze-color"></th>
          <th className="bg-freeze-color"></th>
          <th className="bg-freeze-color"></th>
          <th className="bg-freeze-color"></th>
          <th className="bg-freeze-color"></th>
          <th className="bg-freeze-color"></th>
          <th className="bg-freeze-color"></th>
          <th className="bg-freeze-color"></th>
        </tr>

        <tr>
          <th className="text-capitalize freeze-col">incoming shipment</th>
          <th className="freeze-col-input" style={{left: '16rem'}}></th>
          <th className="freeze-col-input" style={{left: '20rem'}}></th>
          {inComingSales.map((item, index) => {
            return (
              <td className="Table_text">{getIncomingCount(index) || 0}</td>
            );
          })}

          <td className="Table_text">{getIncomingShippingTotalCount().toFixed(2) || 0}</td>
          <th className="bg-freeze-color"></th>
          <th className="bg-freeze-color"></th>
          <th className="bg-freeze-color"></th>
          <th className="bg-freeze-color"></th>
          <th className="bg-freeze-color"></th>
          <th className="bg-freeze-color"></th>
          <th className="bg-freeze-color"></th>
          <th className="bg-freeze-color"></th>
          <th className="bg-freeze-color"></th>
        </tr>

        {/* ******Editable Content******** */}
        <tr>
          <th className="text-capitalize Table-Editable_cell freeze-col">
            Company name
          </th>
          <th
            className="text-capitalize Table-Editable_cell freeze-col-input"
            style={{left: '17rem'}}
          >
            {/* Product rate in $ */}
            <span data-toggle="tooltip" data-placement="top" title="Product rate in $">
                 PR
            </span>
          </th>
          <th
            className="text-capitalize Table-Editable_cell freeze-col-input"
            style={{left: '21rem'}}
          >
            {/* Customer rate in $ */}
            <span data-toggle="tooltip" data-placement="top" title="Customer rate in $">
              CR
            </span>
          </th>

          {halfName.map((halfName: any, index: number) => {
            return (
              <th
                key={index}
                className={`text-capitalize  Table-Editable_cell my-header ${
                  isFocused === index ? 'focused' : ''
                }`}
              >
                {halfName}
              </th>
            );
          })}
          <th className="text-capitalize Table-Editable_cell">
            total
            <br />
            (Cases)
          </th>
          <th className="text-capitalize Table-Editable_cell">incentive</th>
          <th className="text-capitalize Table-Editable_cell">
            invoice
            <br />
            total
          </th>
          <th className="text-capitalize Table-Editable_cell">
            incentive <br /> Total
          </th>
          <th className="text-capitalize Table-Editable_cell">
            after Incentive <br /> total
          </th>
          <th className="text-capitalize Table-Editable_cell">inv#</th>
          <th className="text-capitalize Table-Editable_cell">actions</th>
        </tr>

        {selectedCustomerData?.map((item: any, userIndex: string) => {
          const isStatusGenerated: boolean =
            item?.cust_status?.toLowerCase() === 'generated';

          let customerDropDown: any;
          let customerPrice: number = item?.flavors?.[0]?.price || 0;
          let check_fl_qty: any;
          const incentiveTotal =
            +item.incentive > 0
              ? +item.incentive * GET_totalCasesSold(+userIndex)
              : 0;

          const allCustomer = allCustomerData_forDropdown?.forEach(
            (cu_d: any) => {
              if (+cu_d?.value === +item?.customer_id) {
                customerDropDown = cu_d;
              }
            }
          );

          const alt_customerData = {
            label: item?.companyName,
            id: +item?.customer_id,
            value: +item?.customer_id
          };
          return (
            <tr key={userIndex}>
              <td className="freeze-col-input" style={{left: '0px'}}>
                <div className='d-flex justify-content-between'>
                  <div>
                    <Select
                      className={`searchInput ${
                        (item?.customer_id && 'Table_Btn_disable') || (item?.invoice_id || item?.cust_invoice_id) && 'Table_Btn_disable' 
                      }`}
                      value={customerDropDown || alt_customerData}
                      components={{
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null
                      }}
                      placeholder={flavors?.length <= 0 ? '' : 'Search'}
                      isDisabled={
                        flavors?.length <= 0
                          ? true
                          : false || item?.invoice_id || item?.cust_invoice_id
                          ? true
                          : false
                      }
                      onChange={(selected: any) =>
                        changeCustomerAutocomplete(selected, userIndex)
                      }
                      options={allCustomerData_forDropdown}
                    />
                  </div>
                  {(!item?.customer_id) || (item?.invoice_id || item?.cust_invoice_id) ? <div className='p-3'></div> 
                  :
                  <div className="iconWapper z-100">
                    <img
                      src="/assets/edit.svg"
                      data-toggle="tooltip"
                      id="edit"
                      onClick={() => handleRowClick(item,userIndex)}
                      style={{marginTop:"5px",height:"25px"}}
                      data-html="true"
                      data-bs-placement="top"
                      title="Edit"
                      height="600"
                    />
                    {modalVisible && (
                      <SalesTableModal
                        item={selectedItem}
                        userIndex={companyNameRowIndex}
                        options={allCustomerData_forDropdown}
                        onHide={() => setModalVisible(false)}
                        callBack={changeCustomerAutocomplete}
                      />
                    )}
                  </div> }
                </div>
              </td>

              <td className="freeze-col-input" style={{left: '17rem'}}>
                <input
                  className={`text-capitalize Table-Editable_Rate_input`}
                  value={salesPrice || '--'}
                  type="text"
                  name="salesPrice"
                />
              </td>

              <td className="freeze-col-input" style={{left: '21rem'}}>
                <input
                  id="validationCustom03"
                  className={`text-capitalize form-control Table-Editable_Rate_input hideNumber_controls`}
                  value={customerPrice || ''}
                  type="number"
                  pattern="\d+"
                  name="salesPrice"
                  placeholder="0"
                  onChange={(e) => UpdateRateData(e, userIndex)}
                />
              </td>

              {/* array */}
              {item?.flavors
                ? item?.flavors?.map((fl_item: any, index: number) => {
                    if (+fl_item?.allocated || +fl_item?.qty) {
                      check_fl_qty =
                        +fl_item?.qty > 0
                          ? true
                          : +fl_item?.allocated > 0
                          ? true
                          : false;
                    }
                    return (
                      <td key={index} className="form-group">
                        <input
                          key={index}
                          id="validationCustom03"
                          className={`text-capitalize form-control Table-Editable_input hideNumber_controls`}
                          onFocus={() => setIsFocused(index)}
                          onBlur={() => setIsFocused(null)}
                          type="number"
                          pattern="\d+"
                          name="flavors"
                          placeholder="0"
                          onChange={(e) =>
                            UpdateFlavorData(e, index, userIndex, 'qty')
                          }
                          value={fl_item?.qty === 0 ? '' : fl_item?.qty}
                        />
                      </td>
                    );
                  })
                : flavors?.map(({}: any, index: number) => {
                    return (
                      <td key={index} className="form-group">
                        <input
                          key={index}
                          id="validationCustom03"
                          className="text-capitalize form-control  Table-Editable_input hideNumber_controls"
                          type="number"
                          name="flavors"
                          placeholder="0"
                        />
                      </td>
                    );
                  })}

              <td>
                <input
                  className="text-capitalize Table-Editable_input"
                  value={toFixedWithoutRounding(GET_totalCasesSold(+userIndex), 2)}
                  type="text"
                  name="Total_cases"
                  placeholder="Type Total Cases"
                />
              </td>

              <td>
                <input
                  className="text-capitalize Table-Editable_input"
                  value={item.incentive || customerDropDown?.incentive || 0}
                  type="text"
                  name="incentive"
                  placeholder="incentive"
                />
              </td>
              <td>
                <input
                  className="text-capitalize Table-Editable_input"
                  value={GET_grandTotal(+userIndex)}
                  type="text"
                  name="invoice_total"
                  placeholder="Type invoice totals"
                />
              </td>

              <td>
                <input
                  className="text-capitalize Table-Editable_input"
                  value={incentiveTotal || 0}
                  type="text"
                  name="incentiveTotal"
                  placeholder="incentiveTotal"
                />
              </td>

              <td>
                <input
                  className="text-capitalize Table-Editable_input"
                  value={GET_grandTotal(+userIndex) - incentiveTotal || 0}
                  type="text"
                  name="invoice_total"
                  placeholder="Type invoice totals"
                />
              </td>
              <td>
                <input
                  className="text-capitalize Table-Editable_input"
                  value={item?.invoice_id || item?.cust_invoice_id || 0}
                  type="text"
                  name="inv"
                  placeholder="Type inv"
                />
              </td>
              {/* action buttons will go here */}
              <td className="d_flex">
                <button
                  type="button"
                  onClick={() =>
                    isStatusGenerated
                      ? UpdateSalesData(userIndex)
                      : GenerateSalesData(userIndex)
                  }
                  className={`btn mr-2 btn-primary ${
                    (!item?.customer_id && 'Table_Btn_disable')
                  }`}
                >
                  {isStatusGenerated ? 'Update' : 'Generate'}
                </button>
                {!isStatusGenerated && (
                  <button
                    type="button"
                    onClick={() =>
                      CreateExisting(
                        +item?.customer_id || +item?.id,
                        +userIndex
                      )
                    }
                    className={`btn mr-2 btn-dark ${
                      (flavors?.length <= 0 && 'Table_Btn_disable') ||
                      ((item?.invoice_id || !item?.customer_id) &&
                        'Table_Btn_disable')
                    } ${!check_fl_qty && 'Table_Btn_disable'}`}
                  >
                    Existing
                  </button>
                )}
                <button
                  type="button"
                  className={`btn mr-2 btn-primary hidden_elem ${
                    (flavors?.length <= 0 && 'Table_Btn_disable') ||
                    (!item?.customer_id && 'Table_Btn_disable')
                  } ${!check_fl_qty && 'Table_Btn_disable'}`}
                >
                  Generate in quickbooks
                </button>
              </td>
            </tr>
          );
        })}
      </table>
      <div>
        <button
          onClick={(e) => AddNewCustomerRecord(e)}
          className={`Table_addNewRowBtn ${
            (flavors && flavors?.length <= 0 && 'Table_Btn_disable') ||
            (selectedCustomerData &&
              !selectedCustomerData[selectedCustomerData?.length - 1]
                ?.customer_id &&
              'Table_Btn_disable' &&
              'Table_Btn_disable')
          }`}
        >
          Add New Company
        </button>
      </div>
    </>
  );
};

export default SalesTable;
