import React, {useEffect, useState} from 'react';
import {ContentHeader} from '@components';
import SalesTable from '@app/components/Tables/SalesTable';
import Select from 'react-select';
import {BASE_URL} from '@app/components/constants/app';
import {useDispatch, useSelector} from 'react-redux';
import {progressLoader} from '@app/store/reducers/ui';
import {ScaleLoader} from 'react-spinners';
import {LocalStorage_Keys} from '../enum/LocalStorage_Keys';
import {useNavigate} from 'react-router-dom';
import swal from 'sweetalert';
import apiResponseGenerator from '@app/utils/apiResponseGenerator';
import { numberExtract } from '@app/utils/helpers';
import _ from 'lodash';

const Sales = () => {
  const [products, setProducts] = useState([]);
  const [halfName, setHalfName]: any = useState([]);
  const [productFlavor, setProductFlavor] = useState([]);
  const [selectedOption, setSelectedOption]: any = useState(null);
  const [customers, setCustomers] = useState([]);
  const [price, setPrice] = useState('');
  const [inComingSales, setInComingSales] = useState<any>([]);

  const selectDisable = useSelector((state: any) => {
    return state.ui.SalesProductSelect_disable;
  });
  const [loading, setLoading] = useState(false);
  let saveProductInfo: any = JSON.parse(
    localStorage.getItem(LocalStorage_Keys.CurrentProductInfo) || '{}'
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handelChange = async (e: Event) => {
    if (selectedOption || saveProductInfo.value) {
      const model = await swal({
        title: 'Are your sure?',
        text: 'You want to change the product? your all unsaved data will be lost',
        icon: 'warning',
        buttons: [true, true],
        closeOnClickOutside: false
      });
      if (model) {
        saveProductInfo = null;
        localStorage.removeItem(LocalStorage_Keys.CurrentProductInfo);
        setSelectedOption(e);
      }
    } else {
      setSelectedOption(e);
    }
  };
  const getProducts = async () => {
    setLoading(true);
    const result = await apiResponseGenerator({
      dispatchFunction: dispatch,
      method: 'get',
      url: 'products/getAll'
    });

    if (result) {
      setLoading(false);
      const temp = result.data;
      temp.map((element: any) => {
        return (
          (element['label'] = element['productName']),
          delete element['productName'],
          (element['value'] = element['id'])
        );
      });
      setProducts(temp);
    }
  };
  const fetchingCustomers = async () => {
    const result = await apiResponseGenerator({
      dispatchFunction: dispatch,
      method: 'get',
      url: 'customers/getAll'
    });

    if (result) {
      let getData = result.data;
      setCustomers(getData);
    }
  };

  const fetchProductFlavours = async (id: number) => {
    setHalfName([]);
    const result = await apiResponseGenerator({
      dispatchFunction: dispatch,
      method: 'get',
      url: `productflavors/${id}`
    });

    const getProductFlavorsForSorting = await apiResponseGenerator({
      dispatchFunction: dispatch,
      method: 'get',
      url: `productflavors/getProductFlavors/${id}`
    });

    if (result) {
      let allocated = result.data.product;
      const incoming: any = result.data.incoming;

      const emptyExtraIncoming = allocated.map((data: any) => {
        const findIncoming = incoming.find((d:any) => d.id === data.id)
        const response = findIncoming ? findIncoming : {}
      
        return response
      }) 

      console.log({emptyExtraIncoming})

      setInComingSales(emptyExtraIncoming);

      allocated.map((item: any) => {
        return (item['allocated'] = ''), (item['available'] = '');
      });

       const sortedOrderSetAllocated = await getProductFlavorsForSorting?.data.map((flavors : any) => {
        return {...allocated.find((allocat : any) => allocat.ShortCode === flavors.ShortCode), orderIndexNumber: numberExtract(flavors.ShortCode)}
       })

       const sortedAllocated : any = _.sortBy(sortedOrderSetAllocated, "orderIndexNumber")
      
      setProductFlavor(sortedAllocated);
      
      let temp = sortedAllocated;

      setPrice(temp[0]?.price);

      setTimeout(() => {
        localStorage.removeItem(LocalStorage_Keys.CurrentProductInfo);
      }, 2000);

      if (temp.length >= 0) {
        const shortCodes = temp.map((item: any) => {
          return item?.ShortCode;
        });
        setHalfName(shortCodes);
      }
    }

    return 'success';
  };
  useEffect(() => {
    getProducts();
  }, []);

  useEffect(() => {
    if (saveProductInfo?.id) {
      fetchProductFlavours(saveProductInfo?.id);
      fetchingCustomers();
    }
  }, []);

  useEffect(() => {
    if (selectedOption?.id) {
      fetchProductFlavours(selectedOption.id);
    }
    fetchingCustomers();
  }, [selectedOption]);

  return loading ? (
    <div>
      {/* <ContentHeader title="Sales Allocation" /> */}
      <div
        className="d-flex justify-content-center"
        style={{marginTop: '250px'}}
      >
        <ScaleLoader color="#007bff" height={40} />
      </div>
    </div>
  ) : (
    <div>
      {/* <ContentHeader title="Sales Allocation" /> */}
      <section className="bg-white px-4  rounded " style={{maxHeight: '80%'}}>
        <div>
          <div className="col-4" style={{marginLeft: '-0.5pc'}}>
            <Select
              className="z-index_3"
              value={selectedOption ? selectedOption : saveProductInfo}
              isDisabled={selectDisable}
              onChange={(e: any) => handelChange(e)}
              options={products}
            />
          </div>
        </div>

        <div>
          <SalesTable
            halfName={halfName}
            salesPrice={price}
            customerData={customers}
            productFlavor={productFlavor}
            inComingSales={inComingSales}
            productInfo={selectedOption ? selectedOption : saveProductInfo}
          />
        </div>
      </section>
    </div>
  );
};

export default Sales;
